/**
 * Sanitize the type to be used as a key for the page filters
 * @param {string} type
 * @param {string} workspace
 * @returns {string}
 */
export const getPageKey = ({ type, workspace }) => {
  if (type === 'Report' && workspace !== 'Reports') {
    type = 'Automated report';
  }
  return `${type.trim().toLowerCase().replace(/\s+/g, '_')}_filters`;
};
