import axios from './api';

import { transformAskWhyQuestions } from './utilities/helpers';

/**
 * read AskWhy questions
 * @param {Object} options
 * @param {String} options.documentId
 * @param {String} options.peopleGroupTypeId
 * @param {AbortController} [options.signal]
 * @return {Promise}
 */
export const readAskWhyQuestions = async ({
  documentId: document_id,
  peopleGroupTypeId: people_group_type_id,
  signal,
}) => {
  return axios({
    method: 'get',
    params: {
      document_id,
      people_group_type_id,
    },
    signal,
    url: '/askwhy_questions',
  }).then(({ data }) => transformAskWhyQuestions(data));
};
