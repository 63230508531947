import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  createCustomGroup,
  deleteCustomGroups,
  readCustomGroup,
  readCustomGroups,
  shareCustomGroupWithClient,
  shareCustomGroupWithUsers,
  updateCustomGroupBreakouts,
  updateCustomGroupName,
} from '@api/custom_groups';
import { customGroupStatus } from '@helpers';

import ItemBreakouts from '../../components/ItemBreakouts';
import ListPage from '../../components/ListPage';

import AddBreakout from '../../components/ListPage/components/ActionsToolbar/components/AddBreakout';
import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Duplicate from '../../components/ListPage/components/ActionsToolbar/components/Duplicate';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';
import Share from '../../components/ListPage/components/ActionsToolbar/components/Share';

import { FILTER_OPTIONS, ITEM_COLUMNS } from './utilities/helpers';

const StoreGroups = () => {
  const [isCreateModalActive, setIsCreateModalActive] = useState(false);

  const groups = useSelector((state) => state?.websocket?.customGroups);

  const checkAvailability = (selectedRow) => {
    const group = groups?.find((group) => group?.id === selectedRow?.id);

    if (group) {
      return group?.status >= customGroupStatus.COMPLETED;
    }
  };

  return (
    <>
      <ListPage
        createItem={(params) => createCustomGroup({ ...params, type: 'store' })}
        filterOptions={FILTER_OPTIONS}
        isCreateModalActive={isCreateModalActive}
        itemActions={[
          <AddBreakout
            checkAvailability={checkAvailability}
            groupType="store"
            redirect="/dashboard/create-breakout"
          />,
          <Rename
            checkAvailability={checkAvailability}
            updateName={({ name, row }) => updateCustomGroupName({ id: row.id, name })}
          />,
          <Duplicate
            checkAvailability={checkAvailability}
            createDuplicate={(params) => createCustomGroup({ ...params, type: 'store' })}
            readItem={(params) => readCustomGroup({ ...params, type: 'store' })}
          />,
          <Share
            shareWithClient={shareCustomGroupWithClient}
            shareWithUsers={shareCustomGroupWithUsers}
          />,
          <Delete
            deleteItems={(selectedRows) =>
              deleteCustomGroups({ ids: selectedRows.map(({ id }) => id) })
            }
          />,
        ]}
        itemColumns={ITEM_COLUMNS}
        onCreate={() => setIsCreateModalActive(true)}
        readItems={(params) => readCustomGroups({ ...params, type: 'store' })}
        renderRowExpanded={({ row, itemsPage, handleSubmit }) => (
          <ItemBreakouts
            groupType={{ label: 'Store Group', type: 'store' }}
            item={row?.original}
            itemsPage={itemsPage}
            itemsRow={row?.id}
            onSubmit={handleSubmit}
            readItem={(params) => readCustomGroup({ ...params, type: 'store' })}
            updateItemBreakouts={updateCustomGroupBreakouts}
          />
        )}
        setIsCreateModalActive={setIsCreateModalActive}
        type="Store Group"
      />
    </>
  );
};

export default StoreGroups;
