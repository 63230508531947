import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { OverlayTriggerTooltip, SaveSmartPeopleGroupIcon } from '@utilities';

import AskWhy from '../../../../../../../../../../components/Builder/components/Toolbar/components/AskWhy';
import BinaryDataBar from '../BinaryDataBar';
import ModalSmartPeopleGroup from '@src/app/pages/Dashboard/components/ModalSmartPeopleGroup';
import StatusBar from '../StatusBar';

import styles from './_index.module.scss';

const Node = ({
  answers,
  askWhyInsightsDocument,
  askWhyInstantSurveys,
  askWhySurveyType,
  data,
  documentId,
  isReadOnly,
  onAskWhySubmit,
  reportId,
  reportName,
  type,
}) => {
  const [showModalSmartPeopleGroup, setShowModalSmartPeopleGroup] = useState(false);

  const canAccessAskWhy = useSelector((state) => state?.user?.data?.permissions?.canAccessAskWhy);

  const {
    description = '',
    color = '',
    hasStatusBar = false,
    inline = false,
    level = '',
    nodeAskWhyOption = null,
    nodePeopleGroupOption = null,
    priority = '',
    subdescription = '',
    title = '',
    treeRows = [],
  } = data;

  return (
    <>
      <div
        className={classNames('node-wrapper', styles['node'], {
          [styles['has-connector']]: hasStatusBar,
        })}
        data-testid={`node-${title?.toLowerCase().replaceAll(' ', '-')}`}
        style={{ borderLeftColor: color }}
      >
        <div className={styles['node-title']}>
          <h3>{title}</h3>
          <div className={styles['node-title-actions']}>
            {!isReadOnly && nodePeopleGroupOption && (
              <OverlayTriggerTooltip content="Create Smart People Group">
                <button onClick={() => setShowModalSmartPeopleGroup(true)}>
                  <SaveSmartPeopleGroupIcon />
                </button>
              </OverlayTriggerTooltip>
            )}
            {canAccessAskWhy &&
              askWhyInsightsDocument &&
              askWhySurveyType &&
              !isReadOnly &&
              nodeAskWhyOption && (
                <AskWhy
                  askWhyInsightsDocument={askWhyInsightsDocument}
                  askWhySurveyType={askWhySurveyType}
                  className={styles['node-ask-why']}
                  data-log={`AskWhy_Visit_${nodeAskWhyOption.id}`}
                  data-testid="ask-why-node"
                  id={`ask-why-node-${nodeAskWhyOption.id}`}
                  onAskWhySubmit={onAskWhySubmit}
                  report={{
                    answers,
                    askWhyInstantSurveys,
                    askWhyPeopleGroups: [nodeAskWhyOption],
                    documentId,
                    id: reportId,
                    name: reportName,
                  }}
                />
              )}
          </div>
        </div>
        {description && <p>{description}</p>}
        {subdescription && <p>{subdescription}</p>}
        {hasStatusBar && <StatusBar color={color} level={level} priority={priority} />}
        <ul
          className={classNames({
            [styles['node-tree']]: type === 'tree',
            [styles['node-card']]: type === 'card',
          })}
        >
          {treeRows
            ?.filter((item) => item?.treeCol.length > 0)
            .map((treeRow, index) =>
              type === 'tree' ? (
                <li
                  className={classNames({
                    [styles['node-item']]: treeRow?.orientation === 'vertical',
                  })}
                  key={`treeRow-${index}`}
                >
                  {treeRow?.treeCol.map((col, index) => (
                    <div
                      className={classNames({
                        [styles['is-inline']]: inline,
                        [styles['is-vertical']]: treeRow?.orientation === 'vertical',
                      })}
                      key={`col-${index}`}
                    >
                      {col?.description && <span>{col?.description}</span>}
                      {col?.value && <strong>{col?.value}</strong>}
                    </div>
                  ))}
                </li>
              ) : (
                treeRow?.treeCol.map((col, index) => {
                  const {
                    cardRows,
                    description,
                    type,
                    value,
                    valueNo,
                    valueNoLabel,
                    values,
                    valueYes,
                    valueYesLabel,
                  } = col;
                  return (
                    <li key={`treeRow-${index}`}>
                      <div className={styles['node-list']} key={`col-${index}`}>
                        {value && <strong>{value}</strong>}
                        <div
                          className={classNames({
                            [styles['node-list-card']]: type === 'list' && values,
                          })}
                        >
                          {description && <span>{description}</span>}
                          {type === 'bar' && (
                            <BinaryDataBar
                              valueNo={valueNo}
                              valueNoLabel={valueNoLabel}
                              valueYes={valueYes}
                              valueYesLabel={valueYesLabel}
                            />
                          )}
                          {type === 'list' && values ? (
                            <strong>{values}</strong>
                          ) : (type === 'list' || type === 'unordered-list') && !values ? (
                            // Purchase jurney
                            cardRows &&
                            cardRows.length > 0 && (
                              <ul className={styles['node-list-card-rows']}>
                                {cardRows?.map((cardRow, index) =>
                                  cardRow?.cardCol?.map((column, index) => (
                                    <li key={`cardRow-${index}`}>
                                      <div className={styles['node-column']}>
                                        {column?.description && <span>{column?.description}</span>}
                                        {column?.title && <span>{column?.title}</span>}
                                      </div>
                                    </li>
                                  ))
                                )}
                              </ul>
                            )
                          ) : (
                            cardRows &&
                            cardRows.length > 0 && (
                              <ul className={styles['node-card-rows']}>
                                {cardRows?.map((cardRow, index) =>
                                  cardRow?.cardCol?.map((column, index) => (
                                    <li key={`cardRow-${index}`}>
                                      <div className={styles['node-column']}>
                                        {column?.description && <span>{column?.description}</span>}
                                        {column?.title && <strong>{column?.title}</strong>}
                                      </div>
                                    </li>
                                  ))
                                )}
                              </ul>
                            )
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })
              )
            )}
        </ul>
      </div>
      {showModalSmartPeopleGroup && (
        <ModalSmartPeopleGroup
          answers={answers}
          peopleGroup={nodePeopleGroupOption}
          reportId={reportId}
          reportName={reportName}
          setShowModalSmartPeopleGroup={setShowModalSmartPeopleGroup}
        />
      )}
    </>
  );
};

Node.propTypes = {
  answers: PropTypes.object,
  askWhyInsightsDocument: PropTypes.object,
  askWhyInstantSurveys: PropTypes.array,
  askWhySurveyType: PropTypes.object,
  data: PropTypes.object,
  documentId: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onAskWhySubmit: PropTypes.func,
  reportId: PropTypes.number,
  reportName: PropTypes.string,
  type: PropTypes.string,
};

export default Node;
