import React from 'react';
import PropTypes from 'prop-types';

import {
  handlePageClick,
  HelpIcon,
  LoaderSkeleton,
  OverlayTriggerTooltip,
  Pagination,
  Radio,
  usePaginatedItems,
} from '@utilities';

import { getTooltipContent } from './utilities/helpers';

import styles from './_index.module.scss';

const CustomDateGroups = ({
  activeAnswers,
  customDateGroups,
  isCustomChecked,
  isLoading,
  onSelectAnswer,
  setIsCustomChecked,
}) => {
  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: customDateGroups,
  });

  const onPageChange = (event) => {
    handlePageClick({ event, items: customDateGroups, setOffset });
  };

  const onChange = (value) => {
    onSelectAnswer(value);
    setIsCustomChecked(false);
  };

  if (isLoading)
    return (
      <LoaderSkeleton>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );

  if (!customDateGroups || customDateGroups?.length === 0)
    return <div>No custom date groups found.</div>;

  return (
    <>
      <ul className={styles['custom-date-groups']}>
        {currentItems?.map((customDateGroup, index) => {
          const customDateGroupAnswers = activeAnswers
            ? activeAnswers.filter((activeAnswer) => activeAnswer?.id === customDateGroup?.id)
            : [];

          const isChecked = !isCustomChecked && customDateGroupAnswers?.length === 1;
          const customDateGroupAnswer = isChecked ? activeAnswers[0] : undefined;

          return (
            <li key={`custom-date-option-${index}`}>
              <Radio
                isChecked={isChecked}
                label={customDateGroup?.name}
                onChange={onChange}
                value={customDateGroupAnswer || customDateGroup}
              />
              <OverlayTriggerTooltip content={getTooltipContent(customDateGroup)}>
                <HelpIcon />
              </OverlayTriggerTooltip>
            </li>
          );
        })}
      </ul>
      <Pagination
        onPageChange={onPageChange}
        pageCount={pageCount}
        show={customDateGroups?.length > itemsPerPage}
      />
    </>
  );
};

export default CustomDateGroups;

CustomDateGroups.propTypes = {
  activeAnswers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  customDateGroups: PropTypes.array,
  isCustomChecked: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  setIsCustomChecked: PropTypes.func,
};
