import { useState } from 'react';

import classNames from 'classnames';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Flex from '../../../Layout/components/Flex';

import { CheckboxIcon, CheckboxFilledIcon, CheckboxPartialIcon } from '../../../../icons';

import styles from './_index.module.scss';

const Checkbox = ({
  className,
  helpText,
  id,
  isChecked,
  isDisabled,
  isIndeterminate,
  label,
  onChange,
  title,
  value,
  ...props
}) => {
  const [inputId] = useState(id ? `${id}-input` : nanoid());
  const handleChange = () => {
    if (!isDisabled) {
      onChange(value);
    }
  };

  const renderIcon = () => {
    if (isChecked) return <CheckboxFilledIcon />;
    if (isIndeterminate) return <CheckboxPartialIcon />;
    return <CheckboxIcon />;
  };

  return (
    <div
      className={classNames(styles['checkbox'], className, {
        [styles['is-active']]: isChecked || isIndeterminate,
        [styles['is-disabled']]: isDisabled,
      })}
      id={id}
      {...props}
    >
      <input disabled={isDisabled} id={inputId} onChange={handleChange} type="checkbox" />
      <Flex as="label" gap="small" htmlFor={inputId}>
        <div className={styles['checkbox-icon']}>{renderIcon()}</div>
        <div className={styles['checkbox-text']}>
          <div className={styles['checkbox-label-text']} title={title || label}>
            {label}
          </div>
          {helpText && <div className={styles['checkbox-help-text']}>{helpText}</div>}
        </div>
      </Flex>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  helpText: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
};

export default Checkbox;
