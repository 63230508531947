import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  handlePageClick,
  LoaderSkeleton,
  Pagination,
  Search,
  usePaginatedItems,
} from '@utilities';
import { readCustomGroupAttributeElements } from '@api/custom_groups';

import Selections from '../Selections';
import styles from './_index.module.scss';

const Products = ({
  currentCondition,
  isDebouncing,
  searchQuery,
  setCurrentCondition,
  setSearchQuery,
  signal,
  type,
}) => {
  const [allproducts, setAllproducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchRef = useRef(null);

  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: allproducts,
  });
  const onPageChange = (event) => {
    handlePageClick({ event, items: allproducts, setOffset });
  };

  const handleProductChange = (product) => {
    const productsList = [...currentCondition?.products];
    const productIndex = productsList?.findIndex((pr) => pr?.id === product.id);
    if (productIndex >= 0) {
      productsList.splice(productIndex, 1);
      setCurrentCondition((prevState) => ({
        ...prevState,
        products: [...productsList],
      }));
    } else {
      setCurrentCondition((prevState) => ({
        ...prevState,
        products: [...productsList, product],
      }));
    }
  };

  const getElements = async () => {
    setIsLoading(true);
    if (searchRef?.current) {
      searchRef.current.abort();
    }
    searchRef.current = new AbortController();

    try {
      const response = await readCustomGroupAttributeElements({
        attributeId: currentCondition?.level?.id,
        search: searchQuery || '',
        signal: searchRef.current.signal,
        type,
      });

      setAllproducts(response?.data);
      setIsLoading(false);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentCondition?.level) {
      getElements();
    }
  }, [currentCondition?.level, signal]);

  return (
    <>
      <div className={styles['products-searchbar']}>
        <Search
          hasAutoFocus
          isSearching={(isDebouncing || isLoading) && searchQuery !== ''}
          onChange={setSearchQuery}
          value={searchQuery}
        />
      </div>
      <div className={styles['products-search-results']}>
        {isDebouncing || isLoading ? (
          <div className={styles['products-loader']}>
            <LoaderSkeleton>
              <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
              <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
              <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
              <rect x="0" y="125" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="150" rx="2" ry="2" width="175" height="15" />
              <rect x="0" y="175" rx="2" ry="2" width="225" height="15" />
              <rect x="0" y="200" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="225" rx="2" ry="2" width="225" height="15" />
              <rect x="0" y="250" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="275" rx="2" ry="2" width="225" height="15" />
            </LoaderSkeleton>
          </div>
        ) : (
          <div>
            {currentItems?.length === 0 && (
              <div className={styles['products-empty-message']}>
                <h3>No results found.</h3>
                <p>Try refining your search.</p>
              </div>
            )}
            <ul className={styles['products-list']}>
              {currentItems?.map((item, index) => (
                <li key={index}>
                  <Checkbox
                    isChecked={currentCondition?.products.some((product) => product.id === item.id)}
                    label={item?.name}
                    onChange={() => handleProductChange(item)}
                    value={item?.name}
                  />
                </li>
              ))}
            </ul>
            <Pagination
              onPageChange={onPageChange}
              pageCount={pageCount}
              show={allproducts?.length > itemsPerPage}
            />
          </div>
        )}
        {currentCondition?.products?.length > 0 && (
          <Selections
            title={currentCondition?.level?.name}
            products={currentCondition?.products}
            handleProductChange={handleProductChange}
          />
        )}
      </div>
    </>
  );
};

Products.propTypes = {
  currentCondition: PropTypes?.object,
  isDebouncing: PropTypes.bool,
  searchQuery: PropTypes.string,
  setCurrentCondition: PropTypes.func,
  setSearchQuery: PropTypes.func,
  signal: PropTypes.number,
  type: PropTypes.string,
};
export default Products;
