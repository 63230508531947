/* eslint-disable react/prop-types */

import { memo, useEffect, useState } from 'react';
import intersection from 'lodash/intersection';
import JsxParser from 'react-jsx-parser';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import zipObject from 'lodash/zipObject';

import {
  AISummaryIcon,
  Announcement,
  BarChart,
  BarStackChart,
  BarTornadoChart,
  BubbleChart,
  BulletChart,
  ColumnChart,
  ColumnSplineChart,
  ColumnStackChart,
  Error,
  Footnote,
  FootnoteItem,
  Layout,
  Metadata,
  MetadataItem,
  ScatterRegressionLineChart,
  Select,
  SplineChart,
  TableData,
  VennDiagram,
  WaterfallChart,
} from '@utilities';

import {
  getBarChartData,
  getBarStackChartData,
  getBarStackReversedChartData,
  getBubbleChartData,
  getBulletChartData,
  getColumnChartData,
  getColumnSplineChartData,
  getColumnStackChartData,
  getGroupedStackChartData,
  getScatterRegressionLineChartData,
  getSplineChartData,
  getBarTornadoChartData,
  getWaterfallChartData,
} from './utilities/chart_helpers';

import { getExportName } from './utilities/chart_export_helpers';

import { transformCardReportData, transformTreeReportData } from './utilities/tree_helpers';

import {
  getColumnFilterDefaultOption,
  getColumnFilterOptions,
  getIdFilterDefaultOption,
  getIdFilterOptions,
  getGroupFilterDefaultOption,
  getGroupFilterOptions,
  getRowFilterDefaultOption,
  getRowFilterOptions,
} from './utilities/helpers';

import { getTableData } from './utilities/table_helpers';

import Tree from './components/Tree';

import styles from './_index.module.scss';

const Visualizations = ({
  answers,
  askWhyInsightsDocument,
  askWhyInstantSurveys,
  askWhyPeopleGroups,
  askWhySurveyType,
  data,
  documentId,
  exportData,
  isReadOnly,
  jsx,
  onAskWhySubmit,
  peopleGroupTypes,
  reportId,
}) => {
  const [activeColumnFilters, setActiveColumnFilters] = useState(
    data?.filters?.columns?.map((column) => {
      return getColumnFilterDefaultOption(column);
    })
  );
  const [activeGroupFilters, setActiveGroupFilters] = useState(
    data?.filters.groups?.map((group) => {
      return getGroupFilterDefaultOption({
        group,
        sets: data?.sets,
        type: data?.groupType,
      });
    })
  );

  const [activeIdFilter, setActiveIdFilter] = useState(
    getIdFilterDefaultOption({ id: data?.filters?.id, sets: data?.sets })
  );

  const [activeRowFilters, setActiveRowFilters] = useState(
    data?.filters?.rows?.map((field) => {
      return getRowFilterDefaultOption({
        field,
        set: data?.sets?.find((set) => set?.id === activeIdFilter?.value) || data?.sets[0],
      });
    })
  );

  const activeColumnFilterChartKeys = activeColumnFilters?.map(
    (filter) => filter?.option?.value?.chartKey
  );

  const activeColumnFilterTargets = activeColumnFilters?.map(
    (filter) => filter?.option?.value?.target
  );

  const activeColumnFilter =
    activeColumnFilters?.length > 0
      ? {
          value: {
            chartKey:
              activeColumnFilters?.length > 1
                ? intersection(activeColumnFilterChartKeys[0], activeColumnFilterChartKeys[1])
                : activeColumnFilterChartKeys[0],
            target:
              activeColumnFilters?.length > 1
                ? intersection(activeColumnFilterTargets[0], activeColumnFilterTargets[1])
                : activeColumnFilterTargets[0],
          },
        }
      : null;

  const filteredData = data?.sets
    .filter((set) => (!activeIdFilter ? set : set?.id === activeIdFilter?.value))
    .filter((set) => {
      if (activeGroupFilters?.length < 1) {
        return set;
      }

      let isValid = false;

      activeGroupFilters?.forEach((activeGroupFilter) => {
        isValid =
          set?.metadata?.[data?.groupType][activeGroupFilter?.group] ===
          activeGroupFilter?.option?.value;
      });

      return isValid;
    })
    .map((set) => {
      if (activeRowFilters?.length < 1) return set;

      return {
        ...set,
        data: set?.data?.filter((row) => {
          let isValid = true;

          activeRowFilters?.forEach((activeRowFilter) => {
            if (!isValid) return;

            isValid = row[activeRowFilter?.field] === activeRowFilter?.option?.value;
          });

          return isValid;
        }),
      };
    });

  const handleColumnFilter = ({ label, option }) => {
    const updatedColumnFilter = { label, option };
    const index = activeColumnFilters.findIndex(
      (column) => column?.label === updatedColumnFilter?.label
    );
    activeColumnFilters[index] = updatedColumnFilter;

    setActiveColumnFilters([...activeColumnFilters]);
  };

  const handleGroupFilter = ({ group, option }) => {
    const updatedGroupFilter = { group, option };
    const index = activeGroupFilters.findIndex(
      (group) => group?.group === updatedGroupFilter?.group
    );
    activeGroupFilters[index] = updatedGroupFilter;

    setActiveGroupFilters([...activeGroupFilters]);
  };

  const handleIdFilter = (option) => {
    setActiveIdFilter(option);
  };

  const handleRowFilter = ({ field, option }) => {
    const updatedRowFilter = { field, option };
    const index = activeRowFilters.findIndex((filter) => filter?.field === updatedRowFilter?.field);

    activeRowFilters[index] = updatedRowFilter;

    setActiveRowFilters([...activeRowFilters]);
  };

  useEffect(() => {
    setActiveRowFilters(
      data?.filters?.rows?.map((field) => {
        return getRowFilterDefaultOption({
          field,
          set: data?.sets?.find((set) => set?.id === activeIdFilter?.value) || data?.sets[0],
        });
      })
    );
  }, [activeIdFilter]);

  return (
    <JsxParser
      allowUnknownElements={false}
      blacklistedTags={['Option']}
      className={styles['visualizations']}
      components={{
        Announcement: ({ description, header, priority }) => {
          let icon = null;
          let variant;

          switch (priority) {
            case 'high':
              variant = 'error';
              break;
            case 'medium':
              variant = 'warn';
              break;
            case 'ai_summary':
              variant = 'ai';
              icon = <AISummaryIcon />;
              break;
            default:
              variant = 'info';
          }

          return (
            <Announcement
              hasClose
              header={header}
              icon={icon}
              text={<ReactMarkdown children={description} />}
              variant={variant}
            />
          );
        },
        CardsLayout: () => {
          const cardsData = transformCardReportData({
            askWhyPeopleGroups,
            jsx,
            peopleGroupTypes,
            reportData: filteredData,
          });

          return (
            <Tree
              answers={answers}
              askWhyInsightsDocument={askWhyInsightsDocument}
              askWhyInstantSurveys={askWhyInstantSurveys}
              askWhySurveyType={askWhySurveyType}
              data={cardsData}
              documentId={documentId}
              exportData={exportData}
              isReadOnly={isReadOnly}
              onAskWhySubmit={onAskWhySubmit}
              reportId={reportId}
              type="card"
            />
          );
        },
        Chart: ({
          axis,
          axisFormat,
          baselines,
          defaultDataId,
          defaultSortBy,
          defaultSortDirection,
          groupBy,
          isSum,
          limit,
          orientation,
          size,
          sizeFormat,
          sizeTooltipLabel,
          type,
          xaxis,
          xAxisTickAngle,
          xAxisFormat,
          xAxisTitle,
          xAxisTooltipLabel,
          xCenter,
          xMax,
          xMin,
          yaxis,
          yAxisFormat,
          yAxisTitle,
          yAxisTooltipLabel,
          yCenter,
          yMax,
          yMin,
        }) => {
          if (filteredData?.length === 0) return null;

          const exportName = getExportName(exportData);
          const yAxisDict =
            yaxis && yAxisFormat ? zipObject(yaxis.split(','), yAxisFormat.split(';')) : {};
          const xAxisDict =
            xaxis && xAxisFormat ? zipObject(xaxis.split(','), xAxisFormat.split(';')) : {};
          const sizeDict =
            size && sizeFormat ? zipObject(size.split(','), sizeFormat.split(';')) : {};

          if (orientation === 'v') {
            if (type === 'bar') {
              const columnChartData = getColumnChartData({
                activeColumnFilter,
                filteredData,
                limit,
                xaxis,
                xRotation: xAxisTickAngle,
                yaxis,
                yAxisDict,
              });
              return (
                <div className={styles['visualizations-column-chart']}>
                  <ColumnChart
                    exportName={exportName}
                    series={columnChartData.series}
                    tooltip={columnChartData.tooltip}
                    xAxis={columnChartData.xAxis}
                    yAxis={columnChartData.yAxis}
                  />
                </div>
              );
            }
            if (type === 'grouped-stack') {
              const columnStackChartData = getGroupedStackChartData({
                activeColumnFilter,
                filteredData,
                xaxis,
                yAxisDict,
              });
              return (
                <div className={styles['visualizations-column-chart']}>
                  <ColumnStackChart
                    exportName={exportName}
                    series={columnStackChartData.series}
                    tooltip={columnStackChartData.tooltip}
                    xAxis={columnStackChartData.xAxis}
                    yAxis={columnStackChartData.yAxis}
                  />
                </div>
              );
            }
            if (type === 'stack') {
              const columnStackData = getBarStackReversedChartData({
                activeColumnFilter,
                filteredData,
                xaxis,
                xRotation: xAxisTickAngle,
                yAxisDict,
                yaxis,
              });
              return (
                <div className={styles['visualizations-column-chart']}>
                  <ColumnStackChart
                    exportName={exportName}
                    series={columnStackData.series}
                    tooltip={columnStackData.tooltip}
                    xAxis={columnStackData.xAxis}
                    yAxis={columnStackData.yAxis}
                  />
                </div>
              );
            }
            if (type === 'stack+column') {
              const columnStackData = getColumnStackChartData({
                activeColumnFilter,
                activeIdFilter,
                axis,
                axisFormat,
                data,
                filteredData,
                xaxis,
                yaxis,
              });

              return (
                <div className={styles['visualizations-column-chart']}>
                  <ColumnStackChart
                    exportName={exportName}
                    series={columnStackData.series}
                    tooltip={columnStackData.tooltip}
                    xAxis={columnStackData.xAxis}
                    yAxis={columnStackData.yAxis}
                  />
                </div>
              );
            }
          }
          if (orientation === 'h') {
            if (type === 'bar') {
              const barChartData = getBarChartData({
                activeColumnFilter,
                filteredData,
                limit,
                xaxis,
                xAxisDict,
                yaxis,
              });
              return (
                <div className={styles['visualizations-column-chart']}>
                  <BarChart
                    exportName={exportName}
                    series={barChartData.series}
                    tooltip={barChartData.tooltip}
                    xAxis={barChartData.xAxis}
                    yAxis={barChartData.yAxis}
                  />
                </div>
              );
            }

            if (type === 'stack') {
              const barStackChartData = getBarStackChartData({
                activeColumnFilter,
                filteredData,
                limit,
                xaxis,
                xAxisDict,
                yaxis,
              });

              return (
                <div className={styles['visualizations-column-chart']}>
                  <BarStackChart
                    exportName={exportName}
                    series={barStackChartData.series}
                    tooltip={barStackChartData.tooltip}
                    xAxis={barStackChartData.xAxis}
                    yAxis={barStackChartData.yAxis}
                  />
                </div>
              );
            }
          }

          if (type === 'bullet') {
            const bulletChartData = getBulletChartData({
              activeColumnFilter,
              baselines,
              defaultSortBy,
              defaultSortDirection,
              filteredData,
              limit,
              xaxis,
              xAxisDict,
              yaxis,
            });

            return (
              <div className={styles['visualizations-column-chart']}>
                <BulletChart
                  exportName={exportName}
                  plotOptions={bulletChartData.plotOptions}
                  series={bulletChartData.series}
                  tooltip={bulletChartData.tooltip}
                  xAxis={bulletChartData.xAxis}
                  yAxis={bulletChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'column+spline') {
            const columnSplineChartData = getColumnSplineChartData({
              activeColumnFilter,
              filteredData,
              limit,
              xaxis,
              xRotation: xAxisTickAngle,
              yaxis,
              yAxisDict,
            });

            return (
              <div className={styles['visualizations-column-chart']}>
                <ColumnSplineChart
                  exportName={exportName}
                  plotOptions={columnSplineChartData.plotOptions}
                  series={columnSplineChartData.series}
                  tooltip={columnSplineChartData.tooltip}
                  xAxis={columnSplineChartData.xAxis}
                  yAxis={columnSplineChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'scatter') {
            const bubbleChartData = getBubbleChartData({
              activeColumnFilter,
              filteredData,
              grouping: groupBy,
              limit,
              xaxis,
              xAxisDict,
              xAxisTitle,
              xAxisTooltipLabel,
              xCenter,
              xMax: xMax || 200,
              xMin: xMin || 0,
              yaxis,
              yAxisTitle,
              yAxisDict,
              yAxisTooltipLabel,
              yCenter,
              yMax: yMax || 200,
              yMin: yMin || 0,
              size,
              sizeDict,
              sizeTooltipLabel,
            });

            return (
              <div className={styles['visualizations-column-chart']}>
                <BubbleChart
                  exportName={exportName}
                  series={bubbleChartData.series}
                  tooltip={bubbleChartData.tooltip}
                  xAxis={bubbleChartData.xAxis}
                  yAxis={bubbleChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'scatter+line') {
            const scatterRegressionLineChartData = getScatterRegressionLineChartData({
              filteredData,
              grouping: groupBy,
              xaxis,
              xAxisTitle,
              xMax: xMax || 200,
              xMin: xMin || 0,
              yaxis,
              yAxisDict,
              yAxisTitle,
              yMax: yMax || 200,
              yMin: yMin || 0,
            });

            return (
              <div className={styles['visualizations-column-chart']}>
                <ScatterRegressionLineChart
                  exportName={exportName}
                  series={scatterRegressionLineChartData.series}
                  tooltip={scatterRegressionLineChartData.tooltip}
                  xAxis={scatterRegressionLineChartData.xAxis}
                  yAxis={scatterRegressionLineChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'spline') {
            const splineChartData = getSplineChartData({
              activeColumnFilter,
              filteredData,
              limit,
              xaxis,
              yAxisDict,
              yaxis,
            });
            return (
              <div className={styles['visualizations-column-chart']}>
                <SplineChart
                  exportName={exportName}
                  series={splineChartData.series}
                  tooltip={splineChartData.tooltip}
                  xAxis={splineChartData.xAxis}
                  yAxis={splineChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'tornado') {
            const barTornadoChartData = getBarTornadoChartData({
              activeColumnFilter,
              filteredData,
              limit,
              xaxis,
              xAxisDict,
              yaxis,
            });
            return (
              <div className={styles['visualizations-column-chart']}>
                <BarTornadoChart
                  exportName={exportName}
                  series={barTornadoChartData.series}
                  tooltip={barTornadoChartData.tooltip}
                  xAxis={barTornadoChartData.xAxis}
                  yAxis={barTornadoChartData.yAxis}
                />
              </div>
            );
          }

          if (type === 'waterfall') {
            if (
              defaultDataId !== undefined &&
              !filteredData.some((obj) => obj?.id === defaultDataId)
            ) {
              return null;
            }

            const waterfallData = getWaterfallChartData({
              activeColumnFilter,
              data,
              defaultDataId,
              isSum,
              limit,
              xaxis,
              yaxis,
              yAxisDict,
            });
            return (
              <div className={styles['visualizations-waterfall-chart']}>
                <WaterfallChart
                  exportName={exportName}
                  series={waterfallData.series}
                  tooltip={waterfallData.tooltip}
                  xAxis={waterfallData.xAxis}
                  yAxis={waterfallData.yAxis}
                />
              </div>
            );
          } else {
            return '';
          }
        },
        Column: () => null,
        ColumnFilter: ({ label }) => {
          const options = getColumnFilterOptions({
            filters: data?.filters?.columns,
            label,
          });

          return (
            <div className={styles['visualizations-filter']} data-testid="column-filter">
              <Select
                className={styles['visualizations-filter-select']}
                label={label}
                onChange={(option) => handleColumnFilter({ label, option })}
                options={options}
                value={
                  activeColumnFilters?.find(
                    (activeColumnFilter) => activeColumnFilter?.label === label
                  )?.option
                }
              />
            </div>
          );
        },
        ColumnGroup: () => null,
        DropdownSelector: ({ label }) => {
          return (
            <div className={styles['visualizations-filter']} data-testid="dropdown-selector">
              <Select
                className={styles['visualizations-filter-select']}
                label={label}
                onChange={handleIdFilter}
                options={getIdFilterOptions(data?.sets)}
                value={activeIdFilter}
              />
            </div>
          );
        },
        Error: ({ description, header }) => (
          <Error description={description} header={header} status="failed-report" />
        ),
        Fill: ({ children }) => <Layout.Fill>{children}</Layout.Fill>,
        Filter: ({ label }) => <Select className={styles['visualizations-filter']} label={label} />,
        Flex: ({ children, direction, gap }) => (
          <Layout.Flex direction={direction} gap={gap}>
            {children}
          </Layout.Flex>
        ),
        Footnote: ({ children, title }) => <Footnote children={children} header={title} />,
        FootnoteItem: ({ text }) => <FootnoteItem text={text} />,
        GroupFilter: ({ group, label, type }) => {
          const options = getGroupFilterOptions({
            group,
            sets: data?.sets,
            type: type ? `${type}_grouping` : 'table_grouping',
          });

          return (
            <div className={styles['visualizations-filter']} data-testid="group-filter">
              <Select
                className={styles['visualizations-filter-select']}
                label={label}
                onChange={(option) => handleGroupFilter({ group, option })}
                options={options?.options}
                value={
                  activeGroupFilters?.find(
                    (activeGroupFilter) => activeGroupFilter?.group === group
                  )?.option
                }
              />
            </div>
          );
        },
        ImageItem: ({ caption, image }) => (
          <li className={styles['visualizations-image-item']}>
            <img alt={caption} src={image} />
            <span>{caption}</span>
          </li>
        ),
        ListItem: ({ value }) => <li className={styles['visualizations-list-item']}>{value}</li>,
        ListView: ({ children, title }) => (
          <div>
            <h3>{title}</h3>
            <ul>{children}</ul>
          </div>
        ),
        Metadata: ({ children, header }) => <Metadata children={children} header={header} />,
        MetadataItem: ({ format, name, value }) => {
          const isNumerical = format.includes('0');

          let parsedValue = isNumerical ? parseFloat(value) : value;

          return <MetadataItem format={format} name={name} value={parsedValue} />;
        },
        Option: () => null,
        Panel: ({ children }) => children,
        RowFilter: ({ field, label }) => {
          const options = getRowFilterOptions({
            field,
            set: data?.sets?.find((set) => set?.id === activeIdFilter?.value) || data?.sets[0],
          });

          return (
            <div className={styles['visualizations-filter']} data-testid="row-filter">
              <Select
                className={styles['visualizations-filter-select']}
                label={label}
                onChange={(option) => handleRowFilter({ field, option })}
                options={options?.options}
                value={
                  activeRowFilters.find((activeRowFilter) => activeRowFilter?.field === field)
                    ?.option
                }
              />
            </div>
          );
        },
        Section: ({ children }) => children,
        Sidebar: ({ children }) => <Layout.Sidebar>{children}</Layout.Sidebar>,
        Tab: ({ children }) => children,
        Table: ({ addChart, children, defaultDataId }) => {
          const { columns, rows } = getTableData({
            activeColumnFilter,
            addChart,
            children,
            defaultDataId,
            filteredData,
          });

          if (!columns || !rows) return null;

          return <TableData columns={columns} data={rows} />;
        },
        Tree: () => {
          const treeData = transformTreeReportData({
            askWhyPeopleGroups,
            jsx,
            peopleGroupTypes,
            reportData: filteredData,
          });
          return (
            <Tree
              answers={answers}
              askWhyInsightsDocument={askWhyInsightsDocument}
              askWhyInstantSurveys={askWhyInstantSurveys}
              askWhySurveyType={askWhySurveyType}
              data={treeData}
              documentId={documentId}
              exportData={exportData}
              isReadOnly={isReadOnly}
              onAskWhySubmit={onAskWhySubmit}
              reportId={reportId}
              type="tree"
            />
          );
        },
        VennDiagram: () => {
          const activeKey = `venn_pct${activeColumnFilter?.value?.chartKey[0]}`;
          const currentData = filteredData[0]?.data?.filter((item) => item[activeKey]);
          const vennData = currentData?.length > 0 ? JSON.parse(currentData[0][activeKey]) : [];
          const exportName = getExportName(exportData);
          return <VennDiagram data={vennData} exportName={exportName} />;
        },
      }}
      jsx={jsx}
      showWarnings
    />
  );
};

Visualizations.propTypes = {
  answers: PropTypes.object,
  askWhyInsightsDocument: PropTypes.object,
  askWhyInstantSurveys: PropTypes.array,
  askWhyPeopleGroups: PropTypes.array,
  askWhySurveyType: PropTypes.object,
  data: PropTypes.object,
  documentId: PropTypes.string,
  exportData: PropTypes.object,
  isReadOnly: PropTypes.bool,
  jsx: PropTypes.string,
  onAskWhySubmit: PropTypes.func,
  peopleGroupTypes: PropTypes.array,
  reportId: PropTypes.number,
};

export default memo(Visualizations);
