import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';

import { CopyToClipboard, Pagination, SortIndicator } from '@utilities';
import { getCopyTableValue } from '@utilities/components/TableData/utilities/helpers.js';

import styles from './_index.module.scss';

const OPEN_ENDED_PAGE_SIZE = 20;

const OpenEndedTable = ({ columns, data }) => {
  const {
    getTableProps,
    headerGroups,
    gotoPage,
    page,
    prepareRow,
    rows,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: OPEN_ENDED_PAGE_SIZE },
      manualPagination: false,
    },
    useFlexLayout,
    useSortBy,
    usePagination
  );

  const onPageChange = (event) => {
    gotoPage(event.selected);
  };

  return (
    <div>
      <div className={styles['open-ended-table-actions']}>
        <CopyToClipboard
          text="Copy Table"
          value={getCopyTableValue({
            columns,
            data: rows,
          })}
        />
      </div>
      <table className={styles['open-ended-table']} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  className={classNames({ [styles['is-sortable']]: column?.canSort })}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: column.render('Header') })
                  )}
                >
                  {column.render('Header')} {index === 0 && `(${rows.length})`}
                  <SortIndicator
                    direction={column?.isSortedDesc ? 'desc' : 'asc'}
                    isActive={column?.isSorted}
                    isDisabled={!column?.canSort}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page?.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row?.cells?.map((cell) => (
                  <td {...cell.getCellProps({})} title={cell.value}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        forcePage={pageIndex}
        onPageChange={onPageChange}
        pageCount={Math.ceil(data?.length / OPEN_ENDED_PAGE_SIZE)}
        show={data?.length > OPEN_ENDED_PAGE_SIZE}
      />
    </div>
  );
};

OpenEndedTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
};

export default OpenEndedTable;
