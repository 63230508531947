import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { searchCustomGroupHierarchy } from '@api/custom_groups';
import { formatConditions } from '../../utilities/helpers';

import Madlibs from './components/Madlibs';

const Conditions = ({
  attributes,
  conditions,
  hasBreakoutConditionsElements,
  setConditions,
  setIsUpdatingTable,
  setTableData,
  type,
}) => {
  const [conditionLines, setConditionLines] = useState(() => {
    if (conditions?.breakoutConditions?.length !== 0) {
      const breakoutConditions = conditions.breakoutConditions ?? [];
      const logicalOperators = conditions.logicalOperators ?? [];

      return breakoutConditions.map((condition, index) => ({
        id: condition?.lineId,
        breakoutCondition: condition,
        logicalOperator: logicalOperators[index] || {},
      }));
    }

    return [{ id: 1 }];
  });

  const previousConditionsRef = useRef(conditions);
  const searchRef = useRef(null);

  const getTableData = async ({ search }) => {
    setIsUpdatingTable(true);
    if (searchRef?.current) {
      searchRef.current.abort();
    }
    searchRef.current = new AbortController();

    try {
      const response = await searchCustomGroupHierarchy({
        search,
        signal: searchRef.current.signal,
        type,
      });
      setTableData({
        count: response?.data?.count,
        countWarning: response?.data?.count_warning,
        data: response?.data?.data,
      });
      setIsUpdatingTable(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const previousConditions = previousConditionsRef.current;

    const logicalOperatorsToCompare = conditions?.logicalOperators?.slice(
      0,
      conditions?.breakoutConditions?.length
    );
    const previousLogicalOperatorsToCompare = previousConditions?.logicalOperators?.slice(
      0,
      previousConditions?.breakoutConditions?.length
    );

    if (
      isEqual(conditions?.breakoutConditions, previousConditions?.breakoutConditions) &&
      isEqual(logicalOperatorsToCompare, previousLogicalOperatorsToCompare)
    ) {
      return;
    }

    previousConditionsRef.current = conditions;

    if (
      !conditions?.breakoutConditions ||
      conditions?.breakoutConditions.length === 0 ||
      !hasBreakoutConditionsElements
    ) {
      setTableData({ count: 0, countWarning: false, data: [] });
      setIsUpdatingTable(false);
      return;
    }

    const search = formatConditions({
      conditions: conditions?.breakoutConditions,
      logicalOperators: conditions?.logicalOperators,
    });

    if (search) {
      getTableData({ search });
    }
  }, [conditions]);

  const addLine = () => {
    const newLine = {
      id: conditionLines[conditionLines.length - 1].id + 1,
    };
    setConditionLines((prevLines) => [...prevLines, newLine]);
  };

  const removeLine = (lineId) => {
    setConditionLines((prevConditionLines) =>
      prevConditionLines.filter((line) => line.id !== lineId)
    );

    setConditions((prevConditions) => ({
      ...prevConditions,
      breakoutConditions: prevConditions.breakoutConditions.filter(
        (item) => item.lineId !== lineId
      ),
      logicalOperators: prevConditions.logicalOperators.filter((item) => item.lineId !== lineId),
    }));
  };

  return conditionLines.map((conditionLine, index) => (
    <Madlibs
      conditionLine={conditionLine}
      conditions={conditions}
      key={`madlib-${conditionLine?.id}`}
      levels={attributes}
      lineIndex={index}
      linesLength={conditionLines?.length}
      onAddLine={addLine}
      onRemoveLine={removeLine}
      setConditions={setConditions}
      type={type}
    />
  ));
};

Conditions.propTypes = {
  attributes: PropTypes.array,
  conditions: PropTypes.object,
  hasBreakoutConditionsElements: PropTypes.bool,
  setConditions: PropTypes.func,
  setIsUpdatingTable: PropTypes.func,
  setTableData: PropTypes.func,
  type: PropTypes.string,
};

export default Conditions;
