/* eslint-disable react/prop-types */

import { Tag, TagLockIcon, TagShareIcon } from '@utilities';

import CustomGroupStatus from '../../../components/CustomGroupStatus';

export const ITEM_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return <span>{row?.original?.name}</span>;
    },
    Header: 'Name',
    width: 250,
  },
  {
    accessor: 'status',
    Cell: ({ row }) => {
      return <CustomGroupStatus group={row?.original} />;
    },
    disableSortBy: true,
    Header: 'Status',
    width: 100,
  },
  {
    Cell: ({ row }) => {
      return (
        <>
          {row?.original?.isShared && <Tag icon={<TagShareIcon />} tooltip="Shared" />}
          {row?.original?.isLocked && <Tag icon={<TagLockIcon />} tooltip="Locked" />}
        </>
      );
    },
    disableSortBy: true,
    Header: 'Tags',
    width: 100,
  },
  {
    accessor: 'creator',
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return new Date(row?.original?.createdDatetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    Header: 'Created',
    width: 100,
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'Creator',
    options: [
      {
        label: 'Created by Me',
        value: 'created_by_me_all',
      },
      {
        label: 'Created by Others',
        value: 'created_by_others_all',
      },
    ],
  },
  {
    label: 'Store Groups',
    options: [
      {
        label: 'Shared Group',
        value: 'is_shared_item',
      },
    ],
  },
];
