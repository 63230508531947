import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Announcement, Breadcrumbs, Button, SelectableCard } from '@utilities';
import { createAskWhy } from '@api/instant_survey';
import { updateUserCreditsRemaining } from '@redux/slices/user';

import Cart from './components/Cart';
import ModalConfirmAskWhy from '../../components/ModalConfirmAskWhy';

import { payWithCard, payWithCredits } from './helpers/constants';

import styles from './_index.module.scss';

const Checkout = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const canAccessAskWhy = useSelector((state) => state.user.data?.permissions?.canAccessAskWhy);
  const userSurveyCreditsRemaining = useSelector(
    (state) => state.user.data?.surveyInfo?.userCredits
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const cartItems = location.state?.cartItems || [];
  const isCreditsDisabled =
    userSurveyCreditsRemaining < cartItems.reduce((acc, item) => acc + item.prices.credits, 0);
  const jobId = location.state?.survey?.report.id;
  const paymentOptions = [
    ...(canAccessAskWhy?.canPayWithCredits
      ? [
          {
            ...payWithCredits,
            description: `${payWithCredits.description} ${userSurveyCreditsRemaining}`,
            helperText: isCreditsDisabled
              ? 'Please reach out to your Numerator team for more credits'
              : '',
            isDisabled: isCreditsDisabled,
          },
        ]
      : []),
    ...(canAccessAskWhy?.canPayWithCard ? [payWithCard] : []),
  ];
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    paymentOptions.find((paymentOption) => !paymentOption.isDisabled)
  );

  const handleSubmit = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsSubmitting(true);
    setSubmitError(null);
    const queryParams = `jobId=${jobId}&paymentMethod=${selectedPaymentOption.key}`;
    try {
      const response = await createAskWhy({
        cancelUrl: `${window.location.origin}/dashboard/checkout/cancel?${queryParams}`,
        paymentMethod: selectedPaymentOption.key,
        signal,
        successUrl: `${window.location.origin}/dashboard/checkout/success?${queryParams}`,
        ...location.state.survey,
      });

      switch (selectedPaymentOption.key) {
        case 'credit_card':
          if (response.stripeCheckoutUrl) {
            window.location.href = response.stripeCheckoutUrl;
          } else {
            console.error('No stripe checkout url provided', response);
          }
          break;
        case 'credits':
          dispatch(
            updateUserCreditsRemaining({ userCreditsRemaining: response.userCreditsRemaining })
          );
          return navigate(`/dashboard/checkout/success?${queryParams}`, {
            replace: true,
          });
        default:
          break;
      }
    } catch (error) {
      if (!signal.aborted) {
        setSubmitError(error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Breadcrumbs />
      <h1>Payment</h1>
      <div className={styles['checkout']}>
        <div className={styles['checkout-column']}>
          <h2>Method of Payment</h2>
          {!paymentOptions.length && (
            <Announcement
              header="No payment option available"
              text="Please reach out to your Numerator team to setup payment options"
              variant="warn"
            />
          )}
          {paymentOptions.length > 0 && (
            <ul className={styles['checkout-payment-options']}>
              {paymentOptions.map((paymentOption) => (
                <li key={paymentOption.key}>
                  <SelectableCard
                    data-log={`Checkout_MethodOfPayment_${paymentOption.key}`}
                    data-testid="payment-option"
                    id={`payment-option-${paymentOption.key}`}
                    header={paymentOption.name}
                    helperText={paymentOption.helperText}
                    isActive={paymentOption.key === selectedPaymentOption?.key}
                    isDisabled={paymentOption.isDisabled}
                    onClick={() => setSelectedPaymentOption(paymentOption)}
                    text={paymentOption.description}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={styles['checkout-column']}>
          <h2>Purchase Details</h2>
          <Cart items={cartItems} paymentOption={selectedPaymentOption || paymentOptions[0]} />
          <div className={styles['checkout-actions']}>
            <Button
              data-log="Checkout_BackToReport"
              id="checkout-cancel"
              onClick={() => navigate(-1)}
              text="Back to Report"
              variant="secondary"
            />
            <Button
              data-log={`Checkout_Continue_${selectedPaymentOption?.key}`}
              id="checkout-continue"
              isDisabled={!selectedPaymentOption || cartItems.length === 0}
              onClick={() => setIsConfirmationOpen(true)}
              text="Continue"
            />
          </div>
        </div>
      </div>

      {isConfirmationOpen && (
        <ModalConfirmAskWhy
          isSubmitting={isSubmitting}
          onClose={() => setIsConfirmationOpen(false)}
          onSubmit={handleSubmit}
          panelistCount={100}
          paymentKey={selectedPaymentOption.key}
          submitError={submitError}
          submitText="Checkout"
        />
      )}
    </>
  );
};

export default Checkout;
