import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Announcement,
  Button,
  IconCTA,
  Input,
  isMaxNameLength,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
  PencilIcon,
} from '@utilities';

import styles from './_index.module.scss';

const Rename = ({
  checkAvailability,
  hasException,
  isHoverAction,
  isSelectedFolders,
  isSelectedItems,
  isSelectedOwned,
  onSubmit,
  selectedRows,
  type,
  updateName,
}) => {
  const [error, setError] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const isVisible = selectedRows?.length === 1 && !hasException && isSelectedOwned;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await updateName({ name, row: selectedRows[0] });
      setIsActive(false);
      onSubmit({ refreshFolders: isSelectedFolders, refreshItems: isSelectedItems });
    } catch (error) {
      setError(error?.response?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedRow = selectedRows[0];
    const isActionAvailable = checkAvailability(selectedRow);

    if (isActionAvailable !== undefined) {
      setIsAvailable(isActionAvailable);
    }
  }, [selectedRows]);

  useEffect(() => {
    setIsLoading(false);
    setError([]);
    setName(selectedRows[0]?.name || selectedRows[0]?.title || '');
  }, [isActive]);

  const overlayContent = isAvailable
    ? 'Rename'
    : `Rename will be available after ${type} has finished processing`;

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? overlayContent : ''}>
        <div className={classNames(styles['rename'], { [styles['is-disabled']]: !isAvailable })}>
          <IconCTA
            data-testid="rename-cta"
            icon={<PencilIcon />}
            onClick={() => setIsActive(true)}
            text={isHoverAction ? '' : 'Rename'}
          />
        </div>
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={
              name === '' ||
              name === selectedRows[0]?.name ||
              name === selectedRows[0]?.title ||
              name.trim().length === 0 ||
              isMaxNameLength(name)
            }
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        className={styles['rename-modal']}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title={`Rename ${type}`}
      >
        {error && error?.length > 0 && <Announcement text={error} variant="error" />}
        <Input
          error={
            isMaxNameLength(name)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Name"
          onChange={setName}
          value={name}
        />
      </Modal>
    </>
  );
};

Rename.defaultProps = {
  checkAvailability: () => {},
};

Rename.propTypes = {
  checkAvailability: PropTypes.func,
  hasException: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedCompleted: PropTypes.bool,
  isSelectedFolders: PropTypes.bool,
  isSelectedItems: PropTypes.bool,
  isSelectedOwned: PropTypes.bool,
  onSubmit: PropTypes.func,
  selectedRows: PropTypes.array,
  type: PropTypes.string,
  updateName: PropTypes.func,
};

export default Rename;
