import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogRocket from 'logrocket';
import PropTypes from 'prop-types';

import { ERROR_SAVE_DRAFT } from '@api/instant_survey';
import { createAtlasSurvey } from '@api/surveys';
import {
  HelpIcon,
  Input,
  Layout,
  OverlayTriggerTooltip,
  Pill,
  SaveIcon,
  toast,
  Toolbar,
} from '@utilities';

import ModalSaveAsDraft from '../ModalSaveAsDraft';
import ModalTitleRequired from '../ModalTitleRequired';
import { SurveyContext } from '../../utilities/context';
import { SURVEY_STEPS } from '../../utilities/constants';

import styles from './_index.module.scss';

const SurveyToolbar = ({ isSaveAsDraftDisabled, tools = [] }) => {
  const {
    activeStep,
    insightsDocument,
    instantSurveyType,
    maxQuestions,
    saveInstantSurvey,
    survey,
    updateSurvey,
  } = useContext(SurveyContext);
  const [isSaveAsDraftOpen, setIsSaveAsDraftOpen] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isSurveyTitleModalOpen, setIsSurveyTitleModalOpen] = useState(false);

  const activeQuestions = survey.questions.filter(({ _delete }) => !_delete);
  const navigate = useNavigate();
  const handleOpenSaveAsDraft = () => {
    if (!survey.title.trim()) {
      setIsSurveyTitleModalOpen(true);
    } else {
      setIsSaveAsDraftOpen(true);
    }
  };
  const handleSaveAndExit = async () => {
    setIsSavingDraft(true);
    try {
      const response = await saveInstantSurvey();
      await createAtlasSurvey({
        atlasInstantSurveyRef: response.id,
        id: insightsDocument?.id,
        status: 'draft',
        title: survey.title,
      });
    } catch (error) {
      toast(ERROR_SAVE_DRAFT, { status: 'error' });
      setIsSavingDraft(false);
      return;
    }

    LogRocket.track('InstantSurvey_SaveAsDraft', { type: survey.instantSurveyType });
    navigate({
      pathname: '/dashboard/workspace/surveys',
      search: new URLSearchParams({ filters: 'is_draft_item' }).toString(),
    });
  };

  return (
    <>
      <Toolbar
        className={styles['survey-toolbar']}
        tools={[
          ...tools,
          <OverlayTriggerTooltip content="Save Draft" key="save-as-draft">
            <button
              data-testid="save-draft-button"
              disabled={isSaveAsDraftDisabled}
              onClick={handleOpenSaveAsDraft}
            >
              <SaveIcon />
            </button>
          </OverlayTriggerTooltip>,
          ...(instantSurveyType.helpUrl
            ? [
                <OverlayTriggerTooltip aria-label="Help" content="Help" key="help">
                  <a
                    data-testid="help-link-button"
                    href={instantSurveyType.helpUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <HelpIcon />
                  </a>
                </OverlayTriggerTooltip>,
              ]
            : []),
        ]}
      >
        <Layout.Flex>
          <Input
            className={styles['survey-toolbar-input']}
            onBlur={(title) => updateSurvey({ title: title.trim() })}
            onChange={(title) => updateSurvey({ title })}
            placeholder="Name your survey (e.g., Heavy vs Light Walmart coffee buyers)"
            value={survey.title}
          />
          {activeStep === SURVEY_STEPS.QUESTIONNAIRE && (
            <span className={styles['survey-toolbar-question-count']}>
              <Pill
                isCompleted
                value={activeQuestions.length + '/' + maxQuestions + ' Questions'}
              />
            </span>
          )}
        </Layout.Flex>
      </Toolbar>
      {isSaveAsDraftOpen && (
        <ModalSaveAsDraft
          isActive
          isSaving={isSavingDraft}
          onClose={() => setIsSaveAsDraftOpen(false)}
          onSubmit={handleSaveAndExit}
        />
      )}
      {isSurveyTitleModalOpen && (
        <ModalTitleRequired onClose={() => setIsSurveyTitleModalOpen(false)} />
      )}
    </>
  );
};

SurveyToolbar.propTypes = {
  isSaveAsDraftDisabled: PropTypes.bool,
  tools: PropTypes.array,
};

export default SurveyToolbar;
