import { Fragment, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { GATES } from '@constants';
import {
  Button,
  Checkbox,
  DEFAULT_CHART_COLORS,
  Error,
  FilterGroup,
  Layout,
  Legend,
  Loader,
  LoaderSkeleton,
  ResetFilterIcon,
  Select,
  truncateTextWithEllipsis,
} from '@utilities';

import ComparisonCharts from './components/ComparisonCharts';
import OpenEnded from './components/OpenEnded';

import { DISPLAY_AS_OPTIONS } from './utilities/helpers';
import { SEARCH_PARAM_KEYS } from '../../../../utilities/constants';

import styles from './_index.module.scss';

const Responses = ({
  compareByOptions,
  globalFilters,
  globalFiltersError,
  isDataLoading,
  isFiltersLoading,
  responseData,
  responseError,
  selectedCompareBy,
  survey,
  surveyQuestions,
}) => {
  const [displayAs, setDisplayAs] = useState(DISPLAY_AS_OPTIONS[0]);
  const filterableGroups = useMemo(
    () => survey.groups?.filter(({ quotaGroupRef }) => Boolean(quotaGroupRef)),
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const { value: canQuestionFilter } = useGate(GATES.SURVEY_DASHBOARD_QUESTION_FILTERS);

  const essayResponses = responseData?.essayResponses;
  const surveyResponses = responseData?.surveyResponses;

  const areAnyFiltersSelected = useMemo(() => {
    const {
      [SEARCH_PARAM_KEYS.QUESTION_OPTION]: selectedOptions,
      [SEARCH_PARAM_KEYS.QUOTA_GROUP]: selectedGroups,
      ...otherParams
    } = Object.fromEntries(searchParams);

    return (
      globalFilters?.demographicFilters?.some(
        ({ id }) => otherParams[id]?.split(',')?.length > 0
      ) ||
      selectedOptions?.split(',')?.length > 0 ||
      selectedGroups?.split(',')?.length > 0
    );
  }, [globalFilters, searchParams]);

  const compareByGroups = useMemo(() => {
    switch (selectedCompareBy?.value) {
      case 'overall':
        return [{ color: DEFAULT_CHART_COLORS[0], id: 'Overall', name: 'Overall' }];
      case 'quota_group':
        return survey.groups?.map(({ id, name }, index) => ({
          color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
          id,
          name,
        }));
      default:
        return (
          globalFilters?.demographicFilters
            ?.find(({ id }) => id === selectedCompareBy.value)
            ?.compareBy.map(({ id, label }, index) => ({
              color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
              id,
              name: label,
            })) || []
        );
    }
  }, [selectedCompareBy]);

  const selectedQuotaGroups = useMemo(() => {
    const groupSearchParam = searchParams.get(SEARCH_PARAM_KEYS.QUOTA_GROUP);
    if (groupSearchParam === null)
      return filterableGroups.map(({ quotaGroupRef }) => quotaGroupRef);
    return groupSearchParam.split(',').filter(Boolean);
  }, [filterableGroups, searchParams]);

  const handleFilterChange = ({ filter, value }) => {
    const values = Object.keys(value).filter((key) => value[key]);
    if (!values.length) {
      searchParams.delete(filter.id);
    } else {
      searchParams.set(filter.id, values.join());
    }
    setSearchParams(searchParams);
  };

  const handleGroupFilterChange = ({ quotaGroupRef }) => {
    const currentSelectedGroups = [...selectedQuotaGroups];
    if (currentSelectedGroups.includes(quotaGroupRef)) {
      currentSelectedGroups.splice(currentSelectedGroups.indexOf(quotaGroupRef), 1);
    } else {
      currentSelectedGroups.push(quotaGroupRef);
    }
    searchParams.set(SEARCH_PARAM_KEYS.QUOTA_GROUP, currentSelectedGroups.join());
    setSearchParams(searchParams);
  };

  const handleQuestionFilterChange = ({ filter, value }) => {
    const selectedOptions =
      searchParams
        .get(SEARCH_PARAM_KEYS.QUESTION_OPTION)
        ?.split(',')
        .filter((value) => value) || []; // filter out possible errant empty string `''`
    Object.keys(value).forEach((optionId) => {
      if (value[optionId] && !selectedOptions.includes(optionId)) {
        selectedOptions.push(optionId);
      } else if (!value[optionId] && selectedOptions.includes(optionId)) {
        selectedOptions.splice(selectedOptions.indexOf(optionId), 1);
      }
    });

    if (!selectedOptions.length) {
      searchParams.delete(SEARCH_PARAM_KEYS.QUESTION_OPTION);
    } else {
      searchParams.set(SEARCH_PARAM_KEYS.QUESTION_OPTION, selectedOptions.join());
    }
    setSearchParams(searchParams);
  };

  const handleResetFilters = () => {
    searchParams.delete(SEARCH_PARAM_KEYS.QUESTION_OPTION);
    searchParams.delete(SEARCH_PARAM_KEYS.QUOTA_GROUP);
    globalFilters?.demographicFilters?.forEach(({ id }) => searchParams.delete(id));
    setSearchParams(searchParams);
  };

  if (globalFiltersError) return <Error status={globalFiltersError?.response?.status} />;
  if (isFiltersLoading) return <Loader isCentered />;

  return (
    <Layout.Flex className={styles['responses']} gap="large">
      <Layout.Sidebar className={styles['responses-sidebar']}>
        <Layout.Flex.Column gap="x-large">
          <div className={styles['responses-compare-by']}>
            <Select
              className={styles['responses-filter-select']}
              label="Compare By"
              onChange={({ value }) => {
                searchParams.set(SEARCH_PARAM_KEYS.COMPARE_BY, value);
                setSearchParams(searchParams);
              }}
              options={compareByOptions}
              value={selectedCompareBy}
            />
            <Legend items={compareByGroups} />
          </div>

          <Select
            className={styles['responses-filter-select']}
            label="Display Data In"
            onChange={setDisplayAs}
            options={DISPLAY_AS_OPTIONS}
            value={displayAs}
          />

          {globalFilters?.demographicFilters && (
            <Layout.Flex.Column gap="small">
              <h4>Demographic Filters</h4>
              {globalFilters.demographicFilters.map(({ options, ...props }) => {
                const filter = {
                  options: options.map((option) => ({
                    helpText: `(${option.count})`,
                    ...option,
                  })),
                  ...props,
                };
                const value = searchParams
                  .get(filter.id)
                  ?.split(',')
                  .reduce((acc, prop) => ({ ...acc, [prop]: true }), {});
                return (
                  <FilterGroup
                    filter={filter}
                    key={filter.id}
                    onChange={(newValue) => handleFilterChange({ filter, value: newValue })}
                    value={value}
                  />
                );
              })}
            </Layout.Flex.Column>
          )}

          {filterableGroups?.length > 1 && (
            <Layout.Flex.Column gap="small">
              <h4>Quota Group Filters</h4>
              {filterableGroups.map((group) => (
                <Checkbox
                  id={`group-filter-${group.id}`}
                  isChecked={selectedQuotaGroups.includes(group.quotaGroupRef)}
                  key={group.id}
                  label={truncateTextWithEllipsis({ length: 20, text: group.name })}
                  onChange={() => handleGroupFilterChange(group)}
                  title={group.name}
                  value={group.quotaGroupRef}
                />
              ))}
            </Layout.Flex.Column>
          )}

          {canQuestionFilter && (
            <Layout.Flex.Column gap="small">
              <h4>Question Filters</h4>
              {surveyQuestions
                ?.map((question, index) => ({ ...question, index })) // preserve pre-filtered index
                .filter(({ type }) => type !== QUESTION_TYPES.ESSAY.value)
                .map(({ id, index, options, title, type }) => ({
                  id,
                  label: `Q${index + 1}. ${title}`,
                  options: options?.map(({ id, title }) => ({
                    id,
                    label: title,
                  })),
                  title,
                  type,
                }))
                .map((filter) => {
                  const value = searchParams
                    .get(SEARCH_PARAM_KEYS.QUESTION_OPTION)
                    ?.split(',')
                    .reduce((acc, prop) => ({ ...acc, [prop]: true }), {});

                  return (
                    <FilterGroup
                      filter={filter}
                      key={filter.id}
                      onChange={(newValue) =>
                        handleQuestionFilterChange({ filter, value: newValue })
                      }
                      value={value}
                    />
                  );
                })}
            </Layout.Flex.Column>
          )}

          <Button
            className={styles['responses-filter-reset']}
            icon={<ResetFilterIcon />}
            isDisabled={!areAnyFiltersSelected}
            onClick={handleResetFilters}
            text="Reset Filters"
            variant="secondary"
          />
        </Layout.Flex.Column>
      </Layout.Sidebar>

      <Layout.Fill as={Layout.Flex.Column} className={styles['responses-main']} gap="x-large">
        {responseError && <Error status={responseError?.response?.status} />}
        {!responseError &&
          surveyQuestions?.map((question, index) => (
            <Fragment key={question.id}>
              <div className={styles['responses-question']}>
                <h3 className={styles['responses-question-text']}>
                  Q{index + 1}. {question.title}
                </h3>
                {isDataLoading && (
                  <LoaderSkeleton height={500}>
                    <rect x="0" y="10" rx="2" ry="2" width="250" height="30" />
                    <rect x="0" y="50" rx="2" ry="2" width="1000" height="450" />
                  </LoaderSkeleton>
                )}
                {!isDataLoading && (
                  <>
                    {question.type !== QUESTION_TYPES.ESSAY.value && (
                      <ComparisonCharts
                        compareByGroups={compareByGroups}
                        data={surveyResponses?.find(
                          ({ id, shortname }) =>
                            id === question.id || shortname === question.shortname
                        )}
                        displayAs={displayAs.value}
                        question={question}
                      />
                    )}
                    {question.type === QUESTION_TYPES.ESSAY.value && (
                      <OpenEnded
                        data={essayResponses?.find(
                          ({ id, shortname }) =>
                            id === question.id || shortname === question.shortname
                        )}
                      />
                    )}
                  </>
                )}
              </div>
              {index !== survey.questions.length - 1 && <hr />}
            </Fragment>
          ))}
      </Layout.Fill>
    </Layout.Flex>
  );
};

Responses.propTypes = {
  compareByOptions: PropTypes.array.isRequired,
  globalFilters: PropTypes.object,
  globalFiltersError: PropTypes.object,
  isFiltersLoading: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  responseData: PropTypes.object,
  responseError: PropTypes.object,
  selectedCompareBy: PropTypes.object,
  survey: PropTypes.object.isRequired,
  surveyQuestions: PropTypes.arrayOf(PropTypes.object),
};

export default Responses;
