import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Mustache from 'mustache';
import PropTypes from 'prop-types';

import { getMustachePrompts } from '@helpers';
import {
  AskWhyIcon,
  getFormattedLongDate,
  OverlayPopover,
  OverlayTriggerTooltip,
} from '@utilities';
import { ASK_WHY_QUOTA_SIZE, createAskWhy } from '@api/instant_survey';

import ModalAskWhy from '../../../../../ModalAskWhy';
import ModalConfirmAskWhy from '../../../../../ModalConfirmAskWhy';

import styles from './_index.module.scss';

const AskWhy = ({
  askWhyInsightsDocument,
  askWhySurveyType,
  className,
  onAskWhySubmit,
  report,
  shouldOpenPopover,
  ...props
}) => {
  const [isAskWhyOpen, setIsAskWhyOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const popoverRef = useRef(null);
  const [selectedPeopleGroup, setSelectedPeopleGroup] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [surveyData, setSurveyData] = useState(null);

  const canAccessAskWhy = useSelector((state) => state.user.data?.permissions?.canAccessAskWhy);
  const mustachePrompts = getMustachePrompts(report.answers);
  const panel = useSelector((state) => state?.user?.data?.panel);
  const navigate = useNavigate();

  const selectGroupAndOpenModal = (group) => {
    setSelectedPeopleGroup(group);
    setIsAskWhyOpen(true);
    setIsPopoverOpen(false);
  };

  const handleSubmit = async ({ selectedGroup, selectedQuestions }) => {
    const askWhyData = {
      askWhyInsightsDocument,
      askWhyPeopleGroup: selectedGroup,
      askWhySurveyType,
      country: panel?.abbrev?.toLowerCase(),
      questions: selectedQuestions,
      report,
      title: `AskWhy for ${selectedGroup.label} - ${
        mustachePrompts.categories || mustachePrompts.product || mustachePrompts.place
      } - ${getFormattedLongDate(new Date())}`,
    };

    if (canAccessAskWhy?.canPayWithLicense) {
      setSurveyData(askWhyData);
      setIsAskWhyOpen(false);
      setIsConfirmationOpen(true);
      return;
    }

    navigate('/dashboard/checkout', {
      state: {
        cartItems: [
          {
            description: selectedGroup.label,
            name: `AskWhy for ${report.name}`,
            notes: selectedQuestions.map(({ text }) =>
              Mustache.render(text, { prompt: mustachePrompts })
            ),
            prices: {
              credits: askWhyInsightsDocument.samplePricing.find(
                ({ isAskWhy, responses, type }) =>
                  isAskWhy && responses === ASK_WHY_QUOTA_SIZE && type === 'CREDITS'
              )?.amount,
              USD: askWhyInsightsDocument.samplePricing.find(
                ({ isAskWhy, responses, type }) =>
                  isAskWhy && responses === ASK_WHY_QUOTA_SIZE && type === 'US_DOLLAR'
              )?.amount,
            },
          },
        ],
        survey: askWhyData,
      },
    });
  };

  const handleConfirm = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      await createAskWhy({ signal, ...surveyData });
      setIsConfirmationOpen(false);
      setIsSubmitting(false);

      if (onAskWhySubmit) {
        return onAskWhySubmit();
      }
      navigate(`/dashboard/report/${report.id}/ask-why`);
    } catch (error) {
      if (!signal.aborted) {
        setSubmitError(error);
        setIsSubmitting(false);
      }
    }
  };

  const handleConfirmClose = () => {
    setIsConfirmationOpen(false);
    setIsAskWhyOpen(true);
    setSubmitError(null);
  };

  return (
    <>
      <div ref={popoverRef}>
        <OverlayTriggerTooltip aria-label="AskWhy" content="AskWhy">
          <button
            className={className}
            data-log="AskWhy_Popover"
            onClick={shouldOpenPopover ? () => setIsPopoverOpen(true) : () => setIsAskWhyOpen(true)}
            {...props}
          >
            <AskWhyIcon />
          </button>
        </OverlayTriggerTooltip>
        <OverlayPopover
          className={styles['ask-why']}
          header="AskWhy"
          isActive={isPopoverOpen}
          onHide={() => setIsPopoverOpen(false)}
          target={popoverRef?.current}
        >
          <ul data-testid="ask-why-group-popover">
            {report?.askWhyPeopleGroups?.map((askWhyGroup) => (
              <li key={askWhyGroup.id}>
                <button
                  data-log={`AskWhy_Visit_${askWhyGroup.id}`}
                  id={`ask-why-popover-${askWhyGroup.id}`}
                  onClick={() => selectGroupAndOpenModal(askWhyGroup)}
                >
                  {askWhyGroup?.name}
                </button>
              </li>
            ))}
          </ul>
        </OverlayPopover>
      </div>

      {isAskWhyOpen && (
        <ModalAskWhy
          askWhyGroupId={selectedPeopleGroup?.id}
          mustachePrompts={mustachePrompts}
          onClose={() => setIsAskWhyOpen(false)}
          onSubmit={handleSubmit}
          report={report}
          submitText={canAccessAskWhy?.canPayWithLicense ? 'Send Questions' : 'Continue'}
        />
      )}
      {isConfirmationOpen && (
        <ModalConfirmAskWhy
          isSubmitting={isSubmitting}
          onClose={handleConfirmClose}
          onSubmit={handleConfirm}
          panelistCount={100}
          paymentKey="license"
          submitError={submitError}
          submitText="Send Questions"
        />
      )}
    </>
  );
};

AskWhy.propTypes = {
  askWhyInsightsDocument: PropTypes.object.isRequired,
  askWhySurveyType: PropTypes.object.isRequired,
  className: PropTypes.string,
  onAskWhySubmit: PropTypes.func,
  report: PropTypes.object.isRequired,
  shouldOpenPopover: PropTypes.bool,
};

export default AskWhy;
