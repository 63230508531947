import { useNavigate } from 'react-router-dom';

import {
  createPeopleGroupFolder,
  createPeopleGroupFreeze,
  deletePeopleGroupFolders,
  createPeopleGroupJoin,
  deletePeopleGroups,
  readPeopleGroupAnswers,
  readPeopleGroupFolderItems,
  readPeopleGroupFolders,
  readPeopleGroupItems,
  readPeopleGroupPrompts,
  readStaticGroups,
  sharePeopleGroupWithClient,
  sharePeopleGroupFoldersWithClient,
  sharePeopleGroupsWithUsers,
  sharePeopleGroupFoldersWithUsers,
  updatePeopleGroupFolder,
  updatePeopleGroupFolderName,
  updatePeopleGroupName,
} from '@api/people_groups';

import ItemAnswers from '../../components/ItemAnswers';
import ListPage from '../../components/ListPage';

import AddToFolder from '../../components/ListPage/components/ActionsToolbar/components/AddToFolder';
import Copy from '../../components/ListPage/components/ActionsToolbar/components/Copy';
import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Freeze from '../../components/ListPage/components/ActionsToolbar/components/Freeze';
import Join from '../../components/ListPage/components/ActionsToolbar/components/Join';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';
import Share from '../../components/ListPage/components/ActionsToolbar/components/Share';

import { FILTER_OPTIONS, FOLDER_COLUMNS, ITEM_COLUMNS } from './utilities/helpers';

const PeopleGroups = () => {
  const navigate = useNavigate();

  return (
    <ListPage
      createFolder={createPeopleGroupFolder}
      filterOptions={FILTER_OPTIONS}
      folderActions={[
        <Rename
          type="People Group"
          updateName={({ name, row }) => updatePeopleGroupFolderName({ id: row.id, name })}
        />,
        <Share
          shareWithClient={sharePeopleGroupFoldersWithClient}
          shareWithUsers={sharePeopleGroupFoldersWithUsers}
        />,
        <Delete
          deleteItems={(selectedRows) =>
            deletePeopleGroupFolders({ ids: selectedRows.map(({ id }) => id) })
          }
        />,
      ]}
      folderColumns={FOLDER_COLUMNS}
      itemActions={[
        <Rename
          type="People Group"
          updateName={({ name, row }) => updatePeopleGroupName({ id: row.id, name })}
        />,
        <Copy redirect="/dashboard/create-people-group" />,
        <Freeze createFreeze={createPeopleGroupFreeze} readStaticGroups={readStaticGroups} />,
        <AddToFolder
          createFolder={createPeopleGroupFolder}
          readFolders={readPeopleGroupFolders}
          updateFolder={updatePeopleGroupFolder}
        />,
        <Join createJoin={createPeopleGroupJoin} name="shopper_group" />,
        <Share
          shareWithClient={sharePeopleGroupWithClient}
          shareWithUsers={sharePeopleGroupsWithUsers}
        />,
        <Delete
          deleteItems={(selectedRows) =>
            deletePeopleGroups({ ids: selectedRows.map(({ id }) => id) })
          }
        />,
      ]}
      itemColumns={ITEM_COLUMNS}
      onCreate={() => navigate('/dashboard/create-people-group')}
      readFolderItems={readPeopleGroupFolderItems}
      readFolders={readPeopleGroupFolders}
      readItems={readPeopleGroupItems}
      renderRowExpanded={({ row }) => (
        <ItemAnswers
          item={row?.original}
          readItemAnswers={readPeopleGroupAnswers}
          readPrompts={readPeopleGroupPrompts}
          type="People Group"
        />
      )}
      type="People Group"
    />
  );
};

export default PeopleGroups;
