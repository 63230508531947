export const GROUP_NAME_REQUIRED_ERROR = 'Panelist groups require a name.';
export const GROUP_NAME_UNIQUE_ERROR = 'Panelist group names must be unique.';

export const SURVEY_STEPS = {
  PANELISTS: 0,
  QUESTIONNAIRE: 1,
  REVIEW: 2,
};

export const SURVEY_TEMPORARY_TITLE = 'Untitled';
export const SURVEY_TITLE_REQUIRED_ERROR = 'Survey title is required.';
