import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Mustache from 'mustache';
import PropTypes from 'prop-types';

import {
  Announcement,
  Button,
  Fieldset,
  LoaderSkeleton,
  ModalV2 as Modal,
  Select,
  SelectableCard,
} from '@utilities';
import { readAskWhyQuestions } from '@api/instant_survey';

import styles from './_index.module.scss';

const MAX_ASK_WHY_QUESTIONS = 3;

const ModalAskWhy = ({ askWhyGroupId, mustachePrompts, onClose, onSubmit, report, submitText }) => {
  const askWhyOptions = report.askWhyPeopleGroups?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const [askWhyQuestions, setAskWhyQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchError, setSearchError] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(
    askWhyOptions.find((option) => option.value === askWhyGroupId) || askWhyOptions[0]
  );
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const askWhySignal = useRef(null);
  const canAccessAskWhy = useSelector((state) => state.user.data?.permissions?.canAccessAskWhy);
  const doesAlreadyExist = report.askWhyInstantSurveys?.some(
    (askWhyInstantSurvey) => askWhyInstantSurvey.peopleGroupTypeId === selectedGroup?.value
  );
  const userSurveyCreditsRemaining = useSelector(
    (state) => state?.user?.data?.surveyInfo?.userCredits
  );

  useEffect(() => {
    askWhySignal?.current?.abort();
    askWhySignal.current = new AbortController();

    const getAskWhyQuestions = async () => {
      setAskWhyQuestions([]);
      setIsLoading(true);
      setSearchError(null);
      setSelectedQuestions([]);

      try {
        const questions = await readAskWhyQuestions({
          documentId: report.documentId,
          peopleGroupTypeId: selectedGroup?.value,
          signal: askWhySignal.current.signal,
        });
        setAskWhyQuestions(questions);
      } catch (error) {
        if (!askWhySignal.current?.signal.aborted) {
          setSearchError(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getAskWhyQuestions();
    return () => askWhySignal.current.abort();
  }, [selectedGroup]);

  const toggleSelectedQuestion = (question) => {
    const currentIndex = selectedQuestions.findIndex(
      (selectedQuestion) => selectedQuestion.id === question.id
    );
    if (currentIndex >= 0) {
      setSelectedQuestions(
        selectedQuestions.filter((selectedQuestion) => selectedQuestion.id !== question.id)
      );
    } else if (selectedQuestions.length < MAX_ASK_WHY_QUESTIONS) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  return (
    <Modal
      buttons={[
        <Button
          data-log={`AskWhy_Cancel_${selectedGroup?.value}`}
          id="modal-ask-why-cancel"
          key="cancel-btn"
          onClick={onClose}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          data-log={`AskWhy_Continue_${selectedGroup?.value}`}
          id="modal-ask-why-continue"
          isDisabled={doesAlreadyExist || !selectedGroup || selectedQuestions.length === 0}
          key="submit-btn"
          onClick={() => onSubmit({ selectedGroup, selectedQuestions })}
          text={submitText || 'Continue'}
        />,
      ]}
      className={styles['modal-ask-why']}
      id="modal-ask-why"
      isActive
      isScrollable
      onClose={onClose}
      size="large"
      title="Find Out Why"
    >
      {canAccessAskWhy?.canPayWithCredits && userSurveyCreditsRemaining === 0 && (
        <Announcement
          text="You currently have zero credits available, please reach out to your account team if you'd like to use credits to launch."
          variant="warn"
        />
      )}
      <Select
        className={styles['modal-ask-why-select']}
        label="Panelists (100)"
        isDisabled={askWhyOptions.length === 1}
        onChange={setSelectedGroup}
        options={askWhyOptions}
        value={selectedGroup}
      />
      {searchError && (
        <Announcement
          text="No questions are associated with this group. Please select another."
          variant="error"
        />
      )}
      {isLoading && !searchError && (
        <LoaderSkeleton height={310}>
          <rect x="0" y="0" rx="4" ry="4" width="937" height="70" />
          <rect x="0" y="80" rx="4" ry="4" width="937" height="70" />
          <rect x="0" y="160" rx="4" ry="4" width="937" height="70" />
          <rect x="0" y="240" rx="4" ry="4" width="937" height="70" />
        </LoaderSkeleton>
      )}
      {!isLoading && !searchError && (
        <>
          {doesAlreadyExist && (
            <Announcement
              header="You've already sent questions for this group"
              text="You can create an Instant Survey for an in-depth survey"
              variant="warn"
            />
          )}
          <Fieldset legend={`Questions (${selectedQuestions.length}/${MAX_ASK_WHY_QUESTIONS})`}>
            <ul className={styles['modal-ask-why-questions']}>
              {askWhyQuestions.map((question) => {
                const isSelected = selectedQuestions.some(
                  (selectedQuestion) => selectedQuestion.id === question.id
                );
                const isDisabled =
                  !isSelected && selectedQuestions.length === MAX_ASK_WHY_QUESTIONS;
                return (
                  <li data-testid="ask-why-question" key={question.id}>
                    <SelectableCard
                      data-log={`AskWhy_Questions_${selectedGroup?.value}`}
                      details={
                        question.caption
                          ? Mustache.render(question.caption, { prompt: mustachePrompts })
                          : ''
                      }
                      isActive={isSelected}
                      isDisabled={isDisabled}
                      isMultiSelect
                      onClick={() => toggleSelectedQuestion(question)}
                      text={Mustache.render(question.text, { prompt: mustachePrompts })}
                    />
                  </li>
                );
              })}
            </ul>
            {askWhyQuestions.length === 0 && <div>No results found.</div>}
          </Fieldset>
        </>
      )}
    </Modal>
  );
};

ModalAskWhy.propTypes = {
  askWhyGroupId: PropTypes.string,
  mustachePrompts: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  submitText: PropTypes.string,
};

export default ModalAskWhy;
