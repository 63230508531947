import { useRef } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import { getTreeConfig } from '../../utilities/tree_helpers';

import Control from './components/Control';
import Node from './components/Node';

import styles from './_index.module.scss';

const Tree = ({
  answers,
  askWhyInsightsDocument,
  askWhyInstantSurveys,
  askWhySurveyType,
  data,
  documentId,
  exportData,
  isReadOnly,
  onAskWhySubmit,
  reportId,
  type,
}) => {
  let rows = [];
  let treeType = '';
  let treeData = {};

  const treeRef = useRef();

  const isCard = type === 'card';

  if (isCard) {
    rows = data?.cardBlocks;
  } else {
    const nodes = data?.cardBlocks?.treeNodes;

    rows = Object.values(groupBy(nodes, 'nodeRow'));
    treeType = data?.cardBlocks?.type;
    treeData = getTreeConfig(treeType);
  }

  return (
    <>
      <Control treeRef={treeRef} exportData={exportData} />
      <div className="tree-wrapper" ref={treeRef}>
        {rows?.map((row, rowIndex) => {
          const cardsXS = 12 / row?.length;
          return (
            <div
              className={classNames({
                [styles['tree-row-card']]: isCard,
              })}
              key={`row-container-${rowIndex}`}
            >
              <Row className={styles['tree-row']} key={`row-${rowIndex}`}>
                {row?.map((col, index) => (
                  <Col
                    key={`col-${index}`}
                    xs={{
                      span: treeData?.xs?.[col?.nodeId]
                        ? treeData?.xs?.[col?.nodeId]
                        : cardsXS
                          ? cardsXS
                          : 6,
                      offset: treeData?.xsOffset?.[col?.nodeId],
                    }}
                  >
                    <Node
                      answers={answers}
                      askWhyInsightsDocument={askWhyInsightsDocument}
                      askWhyInstantSurveys={askWhyInstantSurveys}
                      askWhySurveyType={askWhySurveyType}
                      data={col}
                      documentId={documentId}
                      isReadOnly={isReadOnly}
                      onAskWhySubmit={onAskWhySubmit}
                      reportId={reportId}
                      reportName={exportData?.reportName}
                      type={type}
                    />
                  </Col>
                ))}
              </Row>
              {treeData?.connectors ? treeData?.connectors?.[rowIndex] : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

Tree.propTypes = {
  answers: PropTypes.object,
  askWhyInsightsDocument: PropTypes.object,
  askWhyInstantSurveys: PropTypes.array,
  askWhySurveyType: PropTypes.object,
  data: PropTypes.object,
  documentId: PropTypes.string,
  exportData: PropTypes.object,
  isReadOnly: PropTypes.bool,
  onAskWhySubmit: PropTypes.func,
  reportId: PropTypes.number,
  type: PropTypes.string,
};

export default Tree;
