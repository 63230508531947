import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Announcement,
  Button,
  DeleteIcon,
  IconCTA,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
} from '@utilities';

const Delete = ({
  canBulkDelete,
  deleteItems,
  isHoverAction,
  isSelectedFolders,
  isSelectedItems,
  onSubmit,
  selectedRows,
}) => {
  const [error, setError] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await deleteItems(selectedRows);
      setIsActive(false);
      onSubmit({ refreshFolders: isSelectedFolders, refreshItems: isSelectedItems });
    } catch (error) {
      console.error(error);
      setError(error?.response?.data[0] || error?.response?.data?.detail);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setError('');
    setIsLoading(false);
  }, [isActive]);

  const isVisible = canBulkDelete ? true : selectedRows?.length === 1;

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? 'Delete' : ''}>
        <IconCTA
          data-testid="delete-cta"
          icon={<DeleteIcon />}
          onClick={() => setIsActive(true)}
          text={isHoverAction ? '' : 'Delete'}
        />
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            className="delete"
            key="submit-btn"
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Delete"
            variant="error"
          />,
        ]}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title="Delete"
      >
        {error && <Announcement text={error} variant="error" />}
        <p>Are you sure you would like to delete selected?</p>
      </Modal>
    </>
  );
};

Delete.defaultProps = {
  canBulkDelete: true,
};

Delete.propTypes = {
  canBulkDelete: PropTypes.bool,
  deleteItems: PropTypes.func,
  isHoverAction: PropTypes.bool,
  isSelectedFolders: PropTypes.bool,
  isSelectedItems: PropTypes.bool,
  onSubmit: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default Delete;
