import { createInstantSurvey, validateInstantSurvey } from './instantSurvey';
import { createPeopleGroup } from '../people_groups';
import { createAtlasSurvey } from '../surveys';

import { ASK_WHY_QUOTA_SIZE, DEFAULT_GROUP_NAME } from './utilities/constants';

/**
 * create AskWhy
 * @param {Object} options
 * @param {Object} options.askWhyInsightsDocument
 * @param {Object} options.askWhyPeopleGroup
 * @param {Object} options.askWhySurveyType
 * @param {String} [options.cancelUrl]
 * @param {String} [options.country]
 * @param {String} [options.paymentMethod]
 * @param {Object[]} options.questions
 * @param {Object} options.report
 * @param {AbortSignal} [options.signal]
 * @param {String} [options.successUrl]
 * @param {String} options.title
 * @return {Promise}
 */
export const createAskWhy = async ({
  askWhyInsightsDocument,
  askWhyPeopleGroup,
  askWhySurveyType,
  cancelUrl,
  country = 'us',
  paymentMethod,
  questions,
  report,
  signal,
  successUrl,
  title,
}) => {
  const { date_range: dateRange = [null, null] } = report.answers;
  const dateRangePrompt = askWhySurveyType?.prompts.find(
    ({ insightsKey }) => insightsKey === 'date_range'
  );
  const peopleGroupPrompt = askWhySurveyType?.advancedPrompts.find(
    ({ insightsKey }) => insightsKey === 'shopper_group'
  );

  const { data: peopleGroupResponse } = await createPeopleGroup({
    answers: report.answers,
    dateRange,
    id: askWhyPeopleGroup.value,
    isSmart: true,
    name: `${report.name} ${report.id} - ${askWhyPeopleGroup.label}`,
    reportId: report.id,
  });

  const instantSurveyResponse = await createInstantSurvey({
    instantSurvey: {
      askWhyValueMap: {},
      country,
      groups: [
        {
          name: DEFAULT_GROUP_NAME,
          promptAnswers: [
            {
              field: peopleGroupPrompt?.attributes[0]?.field,
              fieldType: 'TEXT',
              operator: 'EQUALS',
              promptId: peopleGroupPrompt?.id,
              values: [
                {
                  attributeId: 'shopper_group',
                  id: `shopper_group:${peopleGroupResponse?.id}`,
                  name: peopleGroupResponse?.name,
                  promptDesc: peopleGroupResponse?.name,
                },
              ],
            },
            ...(dateRange[0] && dateRangePrompt
              ? [
                  {
                    field: dateRangePrompt?.attributes[0]?.field,
                    fieldType: 'DATE',
                    operator: 'IN_RANGE',
                    promptId: dateRangePrompt?.id,
                    values: dateRange,
                  },
                ]
              : []),
          ],
          quotaLimit: ASK_WHY_QUOTA_SIZE,
        },
      ],
      instantSurveyType: askWhySurveyType.id,
      questions,
      title,
    },
    signal,
  });

  await validateInstantSurvey({
    id: instantSurveyResponse.id,
    signal,
  });

  return createAtlasSurvey({
    askWhyDocumentId: askWhyInsightsDocument.id,
    askWhyJobId: report.id,
    askWhyPeopleGroupTypeId: askWhyPeopleGroup.id,
    atlasInstantSurveyRef: instantSurveyResponse.id,
    cancelUrl,
    paymentMethod,
    successUrl,
    title,
  });
};
