/* eslint-disable react/prop-types */

import { Link } from 'react-router-dom';

import {
  ConeIcon,
  ErrorIcon,
  FavoriteIcon,
  FolderIcon,
  Tag,
  TagAskWhyIcon,
  TagLockIcon,
  TagShareIcon,
} from '@utilities';

import ReportStatus from '../../../../../components/ReportStatus';

import styles from './_index.module.scss';

export const ITEM_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return <Link to={`/dashboard/report/${row?.original?.id}`}>{row?.original?.name}</Link>;
    },
    Header: 'Name',
    width: 150,
  },
  {
    accessor: 'type',
    Cell: ({ row }) => {
      return <span title={row?.original?.type}>{row?.original?.type}</span>;
    },
    Header: 'Type',
    width: 100,
  },
  {
    accessor: 'status',
    Cell: ({ row }) => {
      return (
        <ReportStatus
          report={row?.original}
          showTruncatedMessage={true}
          showCompleteMessage={true}
        />
      );
    },
    disableSortBy: true,
    Header: 'Status',
    width: 100,
  },
  {
    Cell: ({ row }) => {
      return (
        <>
          {row?.original?.isShared && <Tag icon={<TagShareIcon />} tooltip="Shared" />}
          {row?.original?.isFavorite && <Tag icon={<FavoriteIcon />} tooltip="Favorite" />}
          {row?.original?.isLocked && <Tag icon={<TagLockIcon />} tooltip="Locked" />}
          {(row?.original?.hasException || row?.original?.hasExpiration) && (
            <Tag icon={<ErrorIcon />} tooltip="Failed" />
          )}
          {row?.original?.hasAskWhy && <Tag icon={<TagAskWhyIcon />} tooltip="AskWhy" />}
          {!row?.original?.isDataWaveStatic && (
            <Tag
              className={styles['pre-data-wave']}
              icon={<ConeIcon />}
              tooltip="Reports pulled before March 10th do not reflect Total Commerce Data. Reprompt reports for updated insights that reflect panel expansion and enhancements."
            />
          )}
        </>
      );
    },
    disableSortBy: true,
    Header: 'Tags',
    width: 100,
  },
  {
    accessor: 'creator',
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return <>{row?.original?.createdDateTime}</>;
    },
    Header: 'Created',
    width: 100,
  },
];

export const FOLDER_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return (
        <span>
          <FolderIcon />
          {row?.original?.name}
        </span>
      );
    },
    Header: 'Name',
    width: 225,
  },
  {
    Cell: ({ row }) => {
      return (
        <>
          {row?.original?.isShared && <Tag icon={<TagShareIcon />} tooltip="Shared" />}
          {row?.original?.isLocked && <Tag icon={<TagLockIcon />} tooltip="Locked" />}
        </>
      );
    },
    disableSortBy: true,
    Header: 'Tags',
    width: 225,
  },
  {
    accessor: 'creator',
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return new Date(row?.original?.createdDatetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    Header: 'Created',
    width: 100,
  },
];

export const getFilterOptions = ({ canAccessAskWhy }) => [
  {
    label: 'Creator',
    options: [
      {
        label: 'Created by Me',
        value: 'created_by_me_all',
      },
      {
        label: 'Created by Others',
        value: 'created_by_others_all',
      },
    ],
  },
  {
    label: 'Folders',
    options: [
      {
        label: 'Shared Folder',
        value: 'is_shared_folder',
      },
    ],
  },
  {
    label: 'Reports',
    options: [
      {
        label: 'Shared Report',
        value: 'is_shared_item',
      },
      {
        label: 'Favorite Report',
        value: 'is_favorite_item',
      },
      ...(canAccessAskWhy
        ? [
            {
              label: 'AskWhy Survey',
              value: 'has_askwhy_item',
            },
          ]
        : []),
    ],
  },
];
