import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogRocket from 'logrocket';

import { Button, ModalV2 as Modal } from '@utilities';

import { updateAreaOfInterest, updateProfile } from '@api/user';
import { updatePersona, updatePersonaSucceeded } from '@redux/slices/user';

import AreaOfInterest from './components/AreaOfInterest';
import Profile from './components/Profile';
import RecommendedReports from './components/RecommendedReports';

import { getDifferenceInDays } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalOnboarding = () => {
  const { isNewUser, persona } = useSelector((state) => state?.user?.data);
  const [isActive, setIsActive] = useState(!persona?.proficiency);
  const [isReportClicked, setIsReportClicked] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedProfile, setSelectedProfile] = useState({
    discipline: persona?.discipline
      ? {
          label: persona?.discipline,
          value: persona?.discipline,
        }
      : null,
    industry: persona?.industry ? { label: persona?.industry, value: persona?.industry } : null,
    jobTitle: persona?.jobTitle,
    proficiency: persona?.proficiency
      ? { label: persona?.proficiency, value: persona?.proficiency }
      : null,
  });

  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState({
    product: null,
    categories: null,
    place: null,
  });

  const dispatch = useDispatch();

  const handleStep1Next = async () => {
    try {
      if (!isNewUser) {
        await dispatch(
          updatePersona({
            persona: {
              discipline: selectedProfile?.discipline?.value,
              industry: selectedProfile?.industry?.value,
              jobTitle: selectedProfile?.jobTitle,
              proficiency: selectedProfile?.proficiency?.value,
            },
          })
        );
        setIsActive(false);
      } else {
        setStep(2);
      }
    } catch (error) {
      console.error(error, 'Could not update user persona.');
    }
  };

  const handleStep2Next = async () => {
    try {
      const [response] = await Promise.all([
        updateProfile({
          profile: {
            discipline: selectedProfile?.discipline?.value,
            industry: selectedProfile?.industry?.value,
            jobTitle: selectedProfile?.jobTitle,
            proficiency: selectedProfile?.proficiency?.value,
          },
        }),
        updateAreaOfInterest({
          areaOfInterest: selectedAreaOfInterest,
        }),
      ]);

      dispatch(updatePersonaSucceeded(response.data));

      setStep(3);
    } catch (error) {
      console.error(error, 'Could not update user persona.');
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleCloseButtonClick = () => {
    if (!isReportClicked) {
      LogRocket.track('Onboarding_Modal_Closed', {
        discipline: selectedProfile?.discipline?.value,
      });
    }
    setIsActive(false);
  };

  const handleOnReportClick = () => {
    LogRocket.track('Onboarding_Modal_Report_Clicked', {
      discipline: selectedProfile?.discipline?.value,
    });
    setIsReportClicked(true);
  };

  useEffect(() => {
    if (getDifferenceInDays({ date: persona?.lastUpdated }) > 365) {
      setIsActive(true);
    }
  }, []);

  const isStep1Disabled =
    !selectedProfile?.discipline ||
    !selectedProfile?.industry ||
    !selectedProfile?.jobTitle.trim() ||
    !selectedProfile?.proficiency;

  const isStep2Disabled = !selectedAreaOfInterest?.product || !selectedAreaOfInterest?.categories;
  const totalSteps = 3;

  return (
    <Modal
      buttons={
        (step === 1 && [
          <Button
            isDisabled={isStep1Disabled}
            key="button"
            onClick={handleStep1Next}
            text={isNewUser ? 'Next' : 'Done'}
          />,
        ]) ||
        (step === 2 && [
          <Button key="back-button" onClick={handleBack} text="Back" variant="secondary" />,
          <Button
            isDisabled={isStep2Disabled}
            key="next-button"
            onClick={handleStep2Next}
            text="Next"
          />,
        ]) ||
        (step === 3 && [<Button key="button" onClick={handleCloseButtonClick} text="Close" />])
      }
      isActive={isActive}
    >
      <div className={styles['modal-onboarding']}>
        {step === 1 && (
          <Profile selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} />
        )}
        {step === 2 && (
          <AreaOfInterest
            selectedAreaOfInterest={selectedAreaOfInterest}
            setSelectedAreaOfInterest={setSelectedAreaOfInterest}
          />
        )}
        {step === 3 && <RecommendedReports onReportClick={handleOnReportClick} />}
      </div>
      {isNewUser && (
        <div className={styles['modal-onboarding-step-indicator']}>
          {Array.from({ length: totalSteps }, (_, i) => (
            <span className={`${step === i + 1 ? styles.active : ''}`} key={i} />
          ))}
        </div>
      )}
    </Modal>
  );
};

export default ModalOnboarding;
