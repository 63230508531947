import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExternalLinkIcon } from '@utilities';
import { deleteAutomatedReports, readAutomatedReports } from '@api/automated_reports';
import { readReportFolders } from '@api/reports';

import ItemAnswers from '../../components/ItemAnswers';
import ListPage from '../../components/ListPage';

import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';

import { FILTER_OPTIONS, ITEM_COLUMNS } from './utilities/helpers.js';

const AutomatedReports = () => {
  const [folders, setFolders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getFolders = async () => {
      try {
        const response = await readReportFolders({});

        const data = response?.data?.folders?.map((folder) => {
          return { label: folder?.name, value: folder?.id };
        });

        setFolders(data);
      } catch (error) {
        console.error(error);
      }
    };

    getFolders();
  }, []);

  const readAutomatedReportDetails = ({ item }) => {
    const reportFolder = folders.find((folder) => {
      return folder.value === item?.reportDestination;
    });

    const scheduledReportsArray = item?.entries.map((entry, index) => {
      const runOnDate = new Date(entry.runDatetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      const pillText = entry?.scheduledJob
        ? entry.name + ' ran on: ' + runOnDate
        : entry.name + ' is scheduled to run on: ' + runOnDate;
      return {
        icon: entry?.scheduledJob ? <ExternalLinkIcon /> : null,
        name: pillText,
        href: entry?.scheduledJob ? `/dashboard/report/${entry?.scheduledJob}` : null,
      };
    });

    const response = {
      data: {
        answers: {
          originalReport: [
            {
              icon: <ExternalLinkIcon />,
              name: `/dashboard/report/${item?.originalReportId}`,
              href: `/dashboard/report/${item?.originalReportId}`,
            },
          ],
          reportDestination: [
            {
              name: item?.reportDestination === null ? 'My Reports' : reportFolder?.label,
            },
          ],
          scheduledReports: scheduledReportsArray,
        },
      },
    };
    return response;
  };

  const readAutomatedReportPrompts = (item) => {
    const response = {
      data: {
        originalReport: { name: 'Original Report' },
        reportDestination: { name: 'Report Destination' },
        scheduledReports: { name: `Scheduled Reports (${item?.entries.length})` },
      },
    };
    return response;
  };

  return (
    <ListPage
      filterOptions={FILTER_OPTIONS}
      itemActions={[
        <Delete
          canBulkDelete={false}
          deleteItems={(selectedRows) =>
            deleteAutomatedReports({ ids: selectedRows.map(({ id }) => id) })
          }
        />,
      ]}
      itemColumns={ITEM_COLUMNS}
      onCreate={() => navigate('/dashboard/reports/')}
      readItems={readAutomatedReports}
      renderRowExpanded={({ row }) => (
        <ItemAnswers
          item={row?.original}
          readItemAnswers={readAutomatedReportDetails}
          readPrompts={readAutomatedReportPrompts}
          type="Report"
        />
      )}
      type="Report"
    />
  );
};

export default AutomatedReports;
