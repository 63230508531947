import axios from './api';

import { formatInstantSurvey, transformInstantSurvey } from './utilities/helpers';

/**
 * create instant survey
 * @param {Object} options
 * @param {Object} options.instantSurvey
 * @param {AbortSignal} [options.signal]
 * @return {Promise}
 */
export const createInstantSurvey = async ({ instantSurvey, signal }) => {
  return axios({
    data: formatInstantSurvey(instantSurvey),
    method: 'post',
    signal,
    url: '/instantsurvey',
  }).then(({ data }) => transformInstantSurvey(data));
};

/**
 * delete instant survey
 * @param {Object} options
 * @param {Number} options.id
 * @return {Promise}
 */
export const deleteInstantSurvey = async ({ id }) => {
  return axios({
    data: {
      deleted: new Date().toISOString(),
    },
    method: 'patch',
    url: `/instantsurvey/${id}`,
  });
};

/**
 * patch instant survey
 * @param {Object} options
 * @param {Object} options.data
 * @param {Number} options.id
 * @return {Promise}
 */
export const patchInstantSurvey = async ({ data, id }) => {
  return axios({
    data,
    method: 'patch',
    url: `/instantsurvey/${id}`,
  }).then(({ data }) => transformInstantSurvey(data));
};

/**
 * read instant survey
 * @param {Object} options
 * @param {Number} options.id
 * @param {Object} [options.params]
 * @param {AbortSignal} [options.signal]
 * @return {Promise}
 */
export const readInstantSurvey = async ({ id, params, signal }) => {
  return axios({
    method: 'get',
    params,
    signal,
    url: `/instantsurvey/${id}`,
  }).then(({ data }) => transformInstantSurvey(data));
};

/**
 * update instant survey
 * @param {Object} options
 * @param {Object} options.instantSurvey
 * @return {Promise}
 */
export const updateInstantSurvey = async ({ instantSurvey }) => {
  const { id, ...instantSurveyData } = formatInstantSurvey(instantSurvey);

  return axios({
    data: {
      ...instantSurveyData,
      // TODO: BE breaking on folder, options, and instant_survey_types being sent
      questions: instantSurveyData.questions.map(({ _delete, id, question_type, text }) => ({
        _delete,
        id,
        question_type,
        text,
      })),
    },
    method: 'put',
    url: `/instantsurvey/${id}`,
  }).then(({ data }) => transformInstantSurvey(data));
};

/**
 * validate instant survey (sample size)
 * @param {Object} options
 * @param {Number} options.id
 * @param {AbortSignal} options.signal
 * @return {Promise}
 */
export const validateInstantSurvey = async ({ id, signal }) => {
  return axios({
    method: 'post',
    signal,
    url: `/instantsurvey/${id}/validate`,
  });
};

/**
 * validate instant survey prompts
 * @param {Object} options
 * @param {Number} options.id
 * @return {Promise}
 */
export const validateInstantSurveyPrompts = async ({ id }) => {
  return axios({
    method: 'post',
    url: `/instantsurvey/${id}/validate_prompts`,
  }).then(({ data }) => data);
};
