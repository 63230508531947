/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */

import { useCallback, useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import Button from '../../components/Button';
import Modal from '../../components/ModalV2';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param blocker
 * @param when
 * @see https://reactrouter.com/api/useBlocker
 */
export const useBlocker = (blocker, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((transition) => {
      const autoUnblockingTransition = {
        ...transition,
        retry() {
          // Unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          transition.retry();
        },
      };

      blocker(autoUnblockingTransition);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param config.message
 * @param config.title
 * @param when
 */
export const usePrompt = ({ message, title }, when = true) => {
  const blocker = useCallback(
    (transition) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');

      document.body.appendChild(element);
      const root = createRoot(element);

      const closePrompt = (state) => {
        if (root) {
          root.unmount();
        }
        if (!state) {
          document.body.removeChild(element);
        } else {
          transition.retry();
        }
      };

      root.render(
        <Modal
          buttons={[
            <Button
              key="cancel"
              onClick={() => closePrompt(false)}
              text="Cancel"
              variant="secondary"
            />,
            <Button
              key="proceed"
              onClick={() => closePrompt(true)}
              text="Proceed"
              variant="primary"
            />,
          ]}
          children={message || 'Changes you made will not be saved.'}
          hasCloseIcon={false}
          isActive
          title={title || 'Confirm Navigation'}
        />,
        element
      );
    },
    [message, title]
  );

  useBlocker(blocker, when);
};
