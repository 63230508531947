/* eslint-disable react/prop-types */

import {
  formatData,
  ErrorIcon,
  FolderIcon,
  Tag,
  TagFrozenIcon,
  TagJoinIcon,
  TagLockIcon,
  TagShareIcon,
  TagSmartGroupIcon,
} from '@utilities';

export const ITEM_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return <>{row?.original?.name}</>;
    },
    Header: 'Name',
    width: 225,
  },
  {
    accessor: 'count',
    Cell: ({ row }) => {
      return row?.original?.count
        ? formatData({ format: '0,0', value: row?.original?.count })
        : '--';
    },
    disableSortBy: true,
    Header: 'Households (L52W)',
    width: 125,
  },
  {
    Cell: ({ row }) => {
      return (
        <>
          {row?.original?.isShared && <Tag icon={<TagShareIcon />} tooltip="Shared" />}
          {row?.original?.isFrozen && <Tag icon={<TagFrozenIcon />} tooltip="Frozen" />}
          {row?.original?.isJoin && <Tag icon={<TagJoinIcon />} tooltip="Joined" />}
          {row?.original?.isSmart && <Tag icon={<TagSmartGroupIcon />} tooltip="Smart" />}
          {row?.original?.isLocked && <Tag icon={<TagLockIcon />} tooltip="Locked" />}
          {row?.original?.hasException && <Tag icon={<ErrorIcon />} tooltip="Failed" />}
        </>
      );
    },
    disableSortBy: true,
    Header: 'Tags',
    width: 100,
  },
  {
    accessor: 'creator',
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return new Date(row?.original?.createdDatetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    Header: 'Created',
    width: 100,
  },
];

export const FOLDER_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return (
        <span>
          <FolderIcon />
          {row?.original?.name}
        </span>
      );
    },
    Header: 'Name',
    width: 225,
  },
  {
    Cell: ({ row }) => {
      return (
        <>
          {row?.original?.isShared && <Tag icon={<TagShareIcon />} tooltip="Shared" />}
          {row?.original?.isLocked && <Tag icon={<TagLockIcon />} tooltip="Locked" />}
        </>
      );
    },
    disableSortBy: true,
    Header: 'Tags',
    width: 225,
  },
  {
    accessor: 'creator',
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return new Date(row?.original?.createdDatetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    Header: 'Created',
    width: 100,
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'Creator',
    options: [
      {
        label: 'Created by Me',
        value: 'created_by_me_all',
      },
      {
        label: 'Created by Others',
        value: 'created_by_others_all',
      },
    ],
  },
  {
    label: 'Folders',
    options: [
      {
        label: 'Shared Folder',
        value: 'is_shared_folder',
      },
    ],
  },
  {
    label: 'People Groups',
    options: [
      {
        label: 'Shared Group',
        value: 'is_shared_item',
      },
      {
        label: 'Joined Group',
        value: 'is_join_item',
      },
      {
        label: 'Frozen Group',
        value: 'is_frozen_item',
      },
      {
        label: 'Smart Group',
        value: 'is_smart_item',
      },
    ],
  },
];
