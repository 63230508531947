import PropTypes from 'prop-types';

import { Layout } from '@utilities';

import NoDataPlaceholder from '../NoDataPlaceholder';
import OpenEndedTable from './components/OpenEndedTable';

const OpenEnded = ({ data }) => {
  if (!data?.segments?.length) return <NoDataPlaceholder />;

  return (
    <Layout.Flex.Column>
      {data.segments.map((segment) => (
        <OpenEndedTable
          columns={segment.table.columns}
          data={segment.table.data}
          key={segment.id}
        />
      ))}
    </Layout.Flex.Column>
  );
};

OpenEnded.propTypes = {
  data: PropTypes.object,
};

export default OpenEnded;
