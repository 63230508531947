import { useNavigate } from 'react-router-dom';

import {
  createTripGroupFolder,
  createTripGroupJoin,
  deleteTripGroupFolders,
  deleteTripGroups,
  readTripGroupAnswers,
  readTripGroupFolders,
  readTripGroupItems,
  readTripGroupPrompts,
  shareTripGroupFoldersWithClient,
  shareTripGroupFoldersWithUsers,
  shareTripGroupWithClient,
  shareTripGroupsWithUsers,
  updateTripGroupFolder,
  updateTripGroupFolderName,
  updateTripGroupName,
} from '@api/trip_groups';

import ItemAnswers from '../../components/ItemAnswers';
import ListPage from '../../components/ListPage';

import AddToFolder from '../../components/ListPage/components/ActionsToolbar/components/AddToFolder';
import Copy from '../../components/ListPage/components/ActionsToolbar/components/Copy';
import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Join from '../../components/ListPage/components/ActionsToolbar/components/Join';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';
import Share from '../../components/ListPage/components/ActionsToolbar/components/Share';

import { FILTER_OPTIONS, FOLDER_COLUMNS, ITEM_COLUMNS } from './utilities/helpers';

const TripGroups = () => {
  const navigate = useNavigate();

  return (
    <ListPage
      createFolder={createTripGroupFolder}
      filterOptions={FILTER_OPTIONS}
      folderActions={[
        <Rename
          type="Trip Group"
          updateName={({ name, row }) => updateTripGroupFolderName({ id: row.id, name })}
        />,
        <Share
          shareWithClient={shareTripGroupFoldersWithClient}
          shareWithUsers={shareTripGroupFoldersWithUsers}
        />,
        <Delete
          deleteItems={(selectedRows) =>
            deleteTripGroupFolders({ ids: selectedRows.map(({ id }) => id) })
          }
        />,
      ]}
      folderColumns={FOLDER_COLUMNS}
      itemActions={[
        <Rename
          type="Trip Group"
          updateName={({ name, row }) => updateTripGroupName({ id: row.id, name })}
        />,
        <Copy redirect="/dashboard/create-trip-group" />,
        <AddToFolder
          createFolder={createTripGroupFolder}
          readFolders={readTripGroupFolders}
          updateFolder={updateTripGroupFolder}
        />,
        <Join createJoin={createTripGroupJoin} name="trip_group" />,
        <Share
          shareWithClient={shareTripGroupWithClient}
          shareWithUsers={shareTripGroupsWithUsers}
        />,
        <Delete
          deleteItems={(selectedRows) =>
            deleteTripGroups({ ids: selectedRows.map(({ id }) => id) })
          }
        />,
      ]}
      itemColumns={ITEM_COLUMNS}
      onCreate={() => navigate('/dashboard/create-trip-group')}
      readFolders={readTripGroupFolders}
      readItems={readTripGroupItems}
      renderRowExpanded={({ row }) => (
        <ItemAnswers
          item={row?.original}
          readItemAnswers={readTripGroupAnswers}
          readPrompts={readTripGroupPrompts}
          type="Trip Group"
        />
      )}
      type="Trip Group"
    />
  );
};

export default TripGroups;
