import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';

const ModalTitleRequired = ({ onClose }) => {
  return (
    <Modal
      buttons={[<Button key="modal-confirm-button" onClick={onClose} text="Okay, Got it" />]}
      isActive
      onClose={onClose}
      title="Missing Survey Name"
    >
      <p>Your survey is unnamed. Enter a survey name to save or launch your survey.</p>
    </Modal>
  );
};

ModalTitleRequired.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalTitleRequired;
